import { dismissLauncher } from '../../../../common/helpers/redirect-flows';
import { showPopup } from '../../../../redux/reducers/popup/popup';
import { POPUP_DISMISS_ACTIONS } from '../../../../redux/reducers/popup/types';
import { Dispatch } from 'redux';

export const GAME_CLIENT_ERRORS = {
  USER_IS_NOT_ELIGIBLE: 'USER_IS_NOT_ELIGIBLE',
  NO_VALID_GEO_APPROVAL: 'NO_VALID_GEO_APPROVAL',
  USER_HAS_NOT_ACCEPTED_TERMS_AND_CONDITIONS:
    'USER_HAS_NOT_ACCEPTED_TERMS_AND_CONDITIONS',
  GENERIC_ERROR: 'GENERIC_ERROR',
};

export type GameClientErrorType =
  (typeof GAME_CLIENT_ERRORS)[keyof typeof GAME_CLIENT_ERRORS];

const GAME_CLIENT_ERRORS_MESSAGE_MAPPER = {
  [GAME_CLIENT_ERRORS.USER_IS_NOT_ELIGIBLE]: {
    errorTitle: "You can't play right now",
    errorMessage:
      'Please check your account settings to make sure everything looks right.',
  },
  [GAME_CLIENT_ERRORS.NO_VALID_GEO_APPROVAL]: {
    errorTitle: 'Location not found',
    errorMessage:
      'Sorry, we checked a few times. Go home and try another game.',
  },
  [GAME_CLIENT_ERRORS.GENERIC_ERROR]: {
    errorTitle: 'Something went wrong',
    errorMessage: 'Failed to launch casino game. ',
  },
};

export const gameClientErrorsHandler = (
  dispatch: Dispatch,
  errorCode: GameClientErrorType,
) => {
  if (
    errorCode === GAME_CLIENT_ERRORS.USER_HAS_NOT_ACCEPTED_TERMS_AND_CONDITIONS
  ) {
    dismissLauncher(
      GAME_CLIENT_ERRORS.USER_HAS_NOT_ACCEPTED_TERMS_AND_CONDITIONS,
    );
  } else {
    dispatch(
      showPopup({
        title: GAME_CLIENT_ERRORS_MESSAGE_MAPPER[errorCode].errorTitle,
        icon: true,
        buttons: {
          dismissAction: {
            title: 'Leave to go home',
            handler: POPUP_DISMISS_ACTIONS.DISMISS_POPUP,
          },
        },
        description: GAME_CLIENT_ERRORS_MESSAGE_MAPPER[errorCode].errorMessage,
      }),
    );
  }
};

