import { datadogRum } from '@datadog/browser-rum';
import { IddUseCases } from '../datadog/enums';

const isDevelopment = process.env.NODE_ENV === 'development';

interface IddRumBase {
    name: string;
    description: string;
    use_case: IddUseCases;
}

export interface IddRumAction extends IddRumBase {
    context?: object;
}

/**
 * @example
 * const ddActionRmGameMount = (context: { gameUrl?: string }, use_case: IddUseCases): IddRumAction => ({
 *      name: 'RM_GAME_MOUNT',
 *      description: 'RM Open Game',
 *      context,
 *      use_case
 * });
 * ddRumAction(ddActionRmGameMount({ gameUrl: 'url' }, DD_USE_CASES.GLOBAL))
 */
export const ddRumAction = (action: IddRumAction, debug = isDevelopment) => {
    const { name, description, context, use_case } = action;

    if (debug && isDevelopment) {
        // eslint-disable-next-line
        console.log(`DD RUM ACTION [${name}]: ${description} - `, { context, use_case });
    }

    datadogRum.addAction(name, { description, ...context, use_case });
};

export interface IddRumError extends IddRumBase {
    context?: object;
    stack?: string;
}

/**
 * @example
 * const ddErrorRmGameMount = (context: { gameUrl?: string }, use_case: IddUseCases): IddRumError => ({
 *      name: 'RM_GAME_MOUNT',
 *      description: 'RM Open Game',
 *      context,
 *      use_case
 * });
 * ddRumError(ddErrorRmGameMount({}, DD_USE_CASES.GLOBAL))
 */
export const ddRumError = (error: IddRumError, debug = isDevelopment) => {
    const { name, description, context, stack, use_case } = error;

    if (debug && isDevelopment) {
        // eslint-disable-next-line
        console.log(`DD RUM ERROR [${name}]: ${description} - `, { error, use_case });
    }

    datadogRum.addError({ name, message: description, stack }, { ...context, use_case }, 'custom');
};
