import { useEffect, useMemo, useState } from 'react';
import logo from '../../../../../assets/icons/SWJ/swj-logo.png';
import { JackpotsField } from '../JackpotsField/JackpotsField';
import { TCPopup } from '../TCPopup/TCPopup';
import { useSelector } from 'react-redux';
import { jackpotFeed, OptInManager } from '@fanduel/casino-swj-consumer';
import { useCallback } from 'preact/hooks';
import { getFgpEndpoint } from '../../../../../common/helpers/get-fgp-endpoint';
import { JackpotWinScreen } from '../JackpotWinScreen/JackpotWinScreen';
import { DebugMenu } from '../../../../../components/DebugMenu/DebugMenu';

const wageredAmount = 0.20.toFixed(2);
const optInOutText = {
    jackpotTextNotOptedIn : 'Opt in for a chance to win a jackpot!',
    jackpotTextOptedIn : 'Amount wagered '
};


export const TopBar = () => {
    require(`./styles/${process.env.APPLICATION}/style.scss`);
    //For social proofing uncomment the code with currentMinorIndex

    const [userOptedIn, setUserOptedIn] = useState(-2); // -2: not visible, -1: intermittent state, 1/0: true/false
    const [currentIndex, setCurrentIndex] = useState(0);
    // const [currentMinorIndex, setCurrentMinorIndex] = useState(0);
    const [jackpots, setJackpots] = useState(null);
    const [showTCPopup, setShowTCPopup] = useState(false);
    const [showJackpotWinScreen, setShowJackpotWinScreen] = useState(false);

    const numberOfJackpots = useMemo(() => (jackpots && Object.keys(jackpots?.tiers).length) || null, [jackpots]);
    const isSpinInProgress = useSelector(state => state?.gameWindow?.spinInProgress);

    const { checkOptedIn, optIn, optOut } = OptInManager(getFgpEndpoint(), 'game_window', {
        callback: setUserOptedIn,
        defaultJackpotId: '1' // to be fetched from prismic
    });

    //receive message from MDS that jackpot has been won. Mock with debug button on dev env that sends jackpot Type
    const [jackpotType, setJackpotType] = useState('mini');

    const instantiateRotation = useCallback(() => {
        // setCurrentMinorIndex(0);
        setCurrentIndex(prev => (prev + 1) % numberOfJackpots);
        // setTimeout(() => {
        //     setCurrentMinorIndex(prev => (prev + 1) % 2);
        // }, 2000);
    }, [numberOfJackpots]);

    useEffect(() => {
        jackpotFeed(setJackpots, true, 5000);
        checkOptedIn();
    }, []);

    useEffect(() => {
        if (numberOfJackpots) {
            instantiateRotation();
        }

        const intervalId = numberOfJackpots
            ? setInterval(() => {
                instantiateRotation();
            }, 5000)
            : null;

        return () => {
            clearInterval(intervalId);
        };
    }, [instantiateRotation, numberOfJackpots]);

    const handleOpenPopup = (e) => {
        e.stopPropagation()
        !isSpinInProgress && setShowTCPopup((prev) => !prev)
    };

    const getOptedInOption = () => {
        return (!userOptedIn ? 'out' : 'in');
    };

    const handleOptInToggle = async () => {
        setUserOptedIn(-1);
        userOptedIn ? await optOut() : await optIn();
    };

    const renderLoadingClass = () => {
        if (userOptedIn === -1) {
            return ' loading';
        }

        if (userOptedIn) {
            return `-opted-in`;
        }

        return '';
    };

    return (
        <>
            <DebugMenu
                setShowJackpotWinScreen={setShowJackpotWinScreen}
                setJackpotType={setJackpotType}
            />
            {!!showJackpotWinScreen && <JackpotWinScreen
                wonJackpot={jackpots?.tiers[jackpotType]}
                setShowJackpotWinScreen={setShowJackpotWinScreen}
                showJackpotWinScreen={showJackpotWinScreen}
            />}

            {!!showTCPopup && <TCPopup
                toggleOptIn={handleOptInToggle}
                userOptedIn={userOptedIn}
                setShowTCPopup={setShowTCPopup}
                renderLoadingClass={renderLoadingClass}
            />}

            {userOptedIn > -2 && !!numberOfJackpots &&
            <div
            className={`swj-top-bar${renderLoadingClass()} ${isSpinInProgress ? 'disabled' : 'enabled'}`}
            onClick={handleOpenPopup}
            >

                <img className="swj-logo" src={logo} />
                <div className="swj-content">
                    <JackpotsField
                        tiers={jackpots.tiers}
                        currentIndex={currentIndex}
                    />
                    <span className="swj-message">{userOptedIn ? `${optInOutText?.jackpotTextOptedIn} $${wageredAmount}`: optInOutText?.jackpotTextNotOptedIn} </span>
                </div>

                <button
                disabled={isSpinInProgress}
                className={`swj-opt-in-button-${getOptedInOption()} ${showTCPopup ? 'show-popup' : ''} ${isSpinInProgress ? 'disabled' : 'enabled'}` }
                onClick={handleOpenPopup}
                >
                    {!showTCPopup ? `Opt ${!userOptedIn ? 'in' : 'out'}` : ''}
                    {showTCPopup && <div className="tc-exit-icon" />}
                </button>
            </div>}
        </>

    );
}
