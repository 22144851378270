const JACKPOT_TYPES = ["mini", "minor", "major", "mega"] as const;

const STATUSES = ["ACTIVE", "INACTIVE"] as const;

type StatusType = (typeof STATUSES)[number];

type JackpotObjectType = {
  id: number;
  name: string;
  prevAmount: number;
  currentAmount: number;
  winnings: number;
  lastWonTime: string | null;
};

type JackpotType = (typeof JACKPOT_TYPES)[number];

export type JackpotFeedResponse = {
  id: number;
  status: StatusType;
  tiers: {
    [key in JackpotType]: JackpotObjectType;
  };
};

let jackpot: JackpotFeedResponse = {
  id: 1,
  status: "ACTIVE",
  tiers: {
    mini: {
      id: 1,
      name: "Mini",
      prevAmount: 1000,
      currentAmount: 1000,
      winnings: 25,
      lastWonTime: null,
    },
    minor: {
      id: 2,
      name: "Minor",
      prevAmount: 10000,
      currentAmount: 10000,
      winnings: 18,
      lastWonTime: null,
    },
    major: {
      id: 3,
      name: "Major",
      prevAmount: 100000,
      currentAmount: 100000,
      winnings: 12,
      lastWonTime: null,
    },
    mega: {
      id: 4,
      name: "Mega",
      prevAmount: 1000000,
      currentAmount: 1000000,
      winnings: 5,
      lastWonTime: null,
    },
  },
};

let counter = 0;

const incrementValues = (init: boolean) => {
  if (init) {
    return jackpot;
  }

  let winnings = 0;

  if (counter === 5) {
    counter = 0;

    winnings = 1;
  } else {
    counter += 1;
  }

  return (Object.keys as unknown as (o: object) => JackpotType[])(
    jackpot.tiers,
  ).reduce((acc, key) => {
    return {
      ...acc,
      tiers: {
        ...acc.tiers,
        [key]: {
          ...jackpot.tiers[key],
          prevAmount: jackpot.tiers[key].currentAmount,
          currentAmount:
            jackpot.tiers[key].currentAmount +
            Math.floor(
              (jackpot.tiers[key].currentAmount / 100) * Math.random(),
            ),
          winnings: jackpot.tiers[key].winnings + winnings,
        },
      },
    };
  }, jackpot as JackpotFeedResponse);
};

const jackpotFeedMock = (init = false) => {
  jackpot = incrementValues(init);
  return jackpot;
};

export default jackpotFeedMock;
