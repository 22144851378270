import axios from "axios";
import Cookie from "js-cookie";
import jwtDecode from "jwt-decode";

import { OriginType } from "./types";

const basePath = "/jackpot-gateway/api/v1";
const cookieName = "X-Auth-Token";

export const getParams = () => {
  const authToken = Cookie.get(cookieName);

  const decodedToken = authToken && (jwtDecode(authToken) as { sub: number });

  if (decodedToken) {
    const userId = decodedToken?.sub;
    return { authToken, userId };
  }

  return null;
};

let intermediateValue: boolean | { [key: string]: boolean };

// // Overload 1: When options are not provided
// export function OptInManager(
//   endpoint: string,
//   origin: OriginType,
// ): {
//   checkOptedIn:
//     | (() => Promise<{ [key: string]: boolean }>)
//     | ((jackpotId: string) => Promise<boolean>);
//   optIn: (jackpotId: string) => Promise<boolean>;
//   optOut: (jackpotId: string) => Promise<boolean>;
// };
// // Overload 2: When options -> callback provided
// export function OptInManager(
//   endpoint: string,
//   origin: OriginType,
//   options: {
//     callback: (arg: boolean | { [key: string]: boolean }) => void;
//   },
// ): {
//   checkOptedIn:
//     | (() => Promise<{ [key: string]: boolean }>)
//     | ((jackpotId: string) => Promise<boolean>);
//   optIn: (jackpotId: string) => Promise<boolean>;
//   optOut: (jackpotId: string) => Promise<boolean>;
// };
// // Overload 3: When options -> defaultJackpotId provided
// export function OptInManager(
//   endpoint: string,
//   origin: OriginType,
//   options: {
//     defaultJackpotId: string;
//   },
// ): {
//   checkOptedIn: () => Promise<boolean>;
//   optIn: () => Promise<boolean>;
//   optOut: () => Promise<boolean>;
// };
//
// // Overload 4: When options are provided
// export function OptInManager(
//   endpoint: string,
//   origin: OriginType,
//   options: {
//     callback: (arg: boolean | { [key: string]: boolean }) => void;
//     defaultJackpotId: string;
//   },
// ): {
//   checkOptedIn: () => Promise<boolean>;
//   optIn: () => Promise<boolean>;
//   optOut: () => Promise<boolean>;
// };
// Implementation
export function OptInManager(
  endpoint: string,
  origin: OriginType,
  options?: {
        defaultJackpotId?: string;
        callback?: (arg: boolean | { [key: string]: boolean }) => void;
    },
) {
  const currentJackpotId = options?.defaultJackpotId || null;
  const currentOrigin = origin || null;
  console.log({ axios })
  const httpClient = axios.create({
    baseURL: endpoint,
  });

  const saveAndCallback = (
    value: { [key: string]: boolean } | boolean,
  ): { [key: string]: boolean } | boolean => {
    intermediateValue = value;
    options?.callback && options.callback(value);

    return value;
  };

  const updateValue = (
    optedInList: string[],
    jackpotId: string | null = null,
  ) => {
    if (currentJackpotId || jackpotId) {
      const optedIn = optedInList.includes(
        currentJackpotId || (jackpotId as string),
      );
      return saveAndCallback(optedIn);
    }

    const listAsObject = optedInList.reduce(
      (acc, val) => ({ ...acc, [val]: true }),
      {},
    );

    return saveAndCallback(listAsObject);
  };

  // async function checkOptedIn(): Promise<boolean>;
  // async function checkOptedIn(): Promise<{ [key: string]: boolean }>;
  // async function checkOptedIn(jackpotId: string): Promise<boolean>;
  async function checkOptedIn(jackpotId?: string) {
    try {
      const result = await httpClient.post(`${basePath}/opted-in`, {
        ...getParams(),
        origin: currentOrigin,
      });

      if (result.status >= 200 && result.status < 300) {
        return updateValue(
          result.data?.jackpotIds,
          jackpotId ?? currentJackpotId,
        );
      }
    } catch (error) {
      console.error("Error checking if opted in: ", error);

      return saveAndCallback(intermediateValue);
    }

    return saveAndCallback(intermediateValue);
  }

  const optIn = async (jackpotId = currentJackpotId): Promise<boolean> => {
    try {
      const result = await httpClient.post(`${basePath}/opt-in`, {
        ...getParams(),
        jackpotId,
        origin: currentOrigin,
      });

      if (result.status >= 200 && result.status < 300) {
        return updateValue(result.data?.jackpotIds, jackpotId) as boolean;
      }
    } catch (error) {
      console.error("Error opting in: ", error);

      return saveAndCallback(intermediateValue) as boolean;
    }

    return saveAndCallback(intermediateValue) as boolean;
  };

  const optOut = async (jackpotId = currentJackpotId): Promise<boolean> => {
    try {
      const result = await httpClient.post(`${basePath}/opt-out`, {
        ...getParams(),
        jackpotId,
        origin: currentOrigin,
      });

      if (result.status >= 200 && result.status < 300) {
        return updateValue(result.data?.jackpotIds, jackpotId) as boolean;
      }
    } catch (error) {
      console.error("Error opting out: ", error);

      return saveAndCallback(intermediateValue) as boolean;
    }

    return saveAndCallback(intermediateValue) as boolean;
  };

  return { checkOptedIn, optIn, optOut };
}
