/* eslint-disable no-console */
import { types } from '../../types';
import { FetchType, getRequestUid } from '../../../common/helpers/fetch-helper';
import { fetchEnd, fetchError, fetchStart } from '../../fetch/fetch';
import { FabricServicesAxios, getFabricURL, getFabricEndpoint } from '../../../common/axios/FabricServicesAxios';
import { getAWCookies, getDomain, getRegion } from '../../../common/helpers/cookies';
import config from '../../../../config/config';
import { commonHeadersAW } from '../../../common/commonHeaders';
import { AW_COOKIE } from '../../../common/constants';
import { ddRumAction } from '../../../common/helpers/datadog-wrapper';
import { ddActionRmCustomerPackageRequest } from '../../../common/datadog/reward-machine/rm-customer-package-request';
import { ddActionRmCustomerPackageReceived } from '../../../common/datadog/reward-machine/rm-customer-package-received';
import { ddActionRmCustomerPackageFailed } from '../../../common/datadog/reward-machine/rm-customer-package-failed';
import { DD_USE_CASES } from '../../../common/datadog/enums';
import { getBrand } from '../../../common/helpers/brand';

const { LOGIN_TOKEN } = AW_COOKIE;

const FEATURES_ENDPOINT = 'features';
const PACKAGE_ENDPOINT = 'package';

const customerDataRequest = async (endpointName, onRequest, onSuccess, onError) => {
    const { authToken, loginToken } = getAWCookies();
    const endpoint = getFabricEndpoint('customerData', endpointName);
    const baseURL = getFabricURL();
    try {
        const requestData = {
            authToken,
            baseURL,
            params: {
                product: config.geoComply.product
            },
            headers: {
                ...commonHeadersAW,
                [LOGIN_TOKEN]: loginToken,
                'x-base-domain': getDomain(),
                'x-region-code': getRegion(),
                'x-brand': getBrand()
            }
        };

        onRequest(requestData);

        const response = await FabricServicesAxios().get(endpoint, requestData);
        const data = response?.data;

        onSuccess(data);
    } catch (error) {
        onError(error);
    }
};

export const getCustomerFeatures = () => async dispatch => {
    const requestUid = getRequestUid(FetchType.CustomerFeatures);
    const onRequest = () => {
        dispatch(fetchStart(requestUid));
        dispatch({ type: types.CUSTOMER_FEATURES_REQUEST });
    };

    const onSuccess = data => {
        dispatch({
            type: types.CUSTOMER_FEATURES_RECEIVED,
            payload: data?.features || []
        });
        dispatch(fetchEnd(requestUid));
    };

    const onError = error => {
        dispatch({
            type: types.CUSTOMER_FEATURES_FAILED,
            payload: error
        });
        dispatch(fetchError(requestUid));
    };

    await customerDataRequest(FEATURES_ENDPOINT, onRequest, onSuccess, onError);
};

export const getCustomerPackage = () => async dispatch => {
    const requestUid = getRequestUid(FetchType.CustomerPackage);
    const onRequest = requestData => {
        dispatch(fetchStart(requestUid));
        dispatch({ type: types.CUSTOMER_PACKAGE_REQUEST });
        ddRumAction(ddActionRmCustomerPackageRequest({ requestData }, DD_USE_CASES.RM_GAME));
    };

    const onSuccess = data => {
        dispatch({
            type: types.CUSTOMER_PACKAGE_RECEIVED,
            payload: { data }
        });
        dispatch(fetchEnd(requestUid));
        ddRumAction(ddActionRmCustomerPackageReceived({ data }, DD_USE_CASES.RM_GAME));
    };

    const onError = error => {
        dispatch({
            type: types.CUSTOMER_PACKAGE_FAILED,
            payload: error
        });
        dispatch(fetchError(requestUid));
        ddRumAction(ddActionRmCustomerPackageFailed({ error }, DD_USE_CASES.RM_GAME));
    };

    await customerDataRequest(PACKAGE_ENDPOINT, onRequest, onSuccess, onError);
};
