import { useSelector } from 'react-redux'
import { useEffect } from 'preact/hooks'

const useRemoveInitialLoader = () => {
    let userId = useSelector(state => state?.session?.userId);

    useEffect(() => {
        if (!userId) {
            return
        }

        const initialLoader = document.getElementById('initial-loader-container')
        if (initialLoader) {
            initialLoader.remove()
        }
    }, [userId])
}

export default useRemoveInitialLoader
