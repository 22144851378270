import { RGIS } from '../../../common/constants';

enum AcceptedProvider {
    IGT = 'IGT',
    LNW = 'LNW',
    DGC = 'DGC',
    EVOLUTION = 'EVOLUTION',
    EVERI = 'EVERI',
    HIGH5 = 'HIGH5',
    ARISTOCRAT = 'ARISTOCRAT',
    SPINGAMING = 'SPINGAMING',
    REALMS = 'SLINGO',
    EZUGI = 'EZUGI',
    NETENT = 'NETENT',
    PLAYTECH = 'PLAYTECH',
    PRAGMATIC = 'PRAGMATIC',
    AWAGER = 'AWAGER'
}

type ProviderMap = {
    [provider: string]: AcceptedProvider;
};

const providerNameMap = (rgi: string): ProviderMap => ({
    RGS: AcceptedProvider.IGT,
    NETENT: rgi === RGIS.FGP ? AcceptedProvider.EVOLUTION : AcceptedProvider.NETENT,
    EVOLUTION: AcceptedProvider.EVOLUTION,
    EVERI: AcceptedProvider.EVERI,
    NYX: AcceptedProvider.LNW,
    HIGH5: AcceptedProvider.HIGH5,
    DGC: AcceptedProvider.DGC,
    ARISTOCRAT: AcceptedProvider.ARISTOCRAT,
    SPINGAMING: AcceptedProvider.SPINGAMING,
    SPIN: AcceptedProvider.SPINGAMING,
    EZUGI: AcceptedProvider.EZUGI,
    REALMS: AcceptedProvider.REALMS,
    SLINGO: AcceptedProvider.REALMS,
    PLAYTECH: AcceptedProvider.PLAYTECH,
    PRAGMATIC: AcceptedProvider.PRAGMATIC,
    AWAGER: AcceptedProvider.AWAGER
});

export const mapProvider = (input: string, rgi: string): AcceptedProvider => {
    return providerNameMap(rgi)[input.toUpperCase()];
};
