import { createSelector } from 'reselect';
import { PLATFORM } from '../../common/constants/game-constants';
import { getAppPlatform } from '../../common/helpers/app-platform';

const gameWindowSelector = state => state?.gameWindow?.gameWindowUI;

const variantMapper = {
    [PLATFORM.Android]: 'android',
    [PLATFORM.Web]: 'web',
    [PLATFORM.Xsell]: 'xsell-android'
};

export const variantSelector = createSelector(gameWindowSelector, gameWindow => {
    const targetVariant = variantMapper[getAppPlatform()];

    return gameWindow?.data
        ? Object.values(gameWindow.data).find(value => Array.isArray(value) && value?.find(val => val?.primary?.variant === targetVariant))
        : undefined;
});

export const gameWindowConfigSelector = createSelector(variantSelector, variant =>
    variant?.find(elem => elem?.slice_type === 'game_window')
);
