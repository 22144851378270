import config from '../../../config/config';
import { GAME_PROVIDER, GAME_PROVIDER_NAME } from '../constants/game-constants';
import { getRegion } from './cookies';
import { getUserChannel, getUserOS } from './platform';
import { getQueryParameter } from './queryparams';

const getGameSpecificChannel = () => {
    const channel = getUserChannel().toUpperCase();
    return ['DESKTOP', 'PHONE', 'TABLET'].includes(channel) ? channel : 'UNKNOWN';
};

//todo: ask QAs who have access to devices to validate what platform gives on devices and stuff.
// this could prove to be completely useless or more complicated than initially considered
const getGameSpecificOS = () => {
    const userOS = getUserOS().toUpperCase();

    let os = 'UNKNOWN';
    switch (userOS) {
        case 'ANDROID':
            os = 'ANDROID';
            break;
        case 'MAC':
            os = 'MAC';
            break;
        case 'WINDOWS':
            os = 'WINDOWS';
            break;
        case 'LINUX':
            os = 'LINUX';
            break;
        case 'IOS':
            os = 'IOS';
            break;
    }

    return os;
};

const getGameSpecificProvider = () => {
    const provider = getQueryParameter('providerName');
    return providerToGameSpecificProvider(provider);
};

const providerToGameSpecificProvider = provider => {
    switch (provider?.toUpperCase()) {
        case GAME_PROVIDER.NETENT:
            return GAME_PROVIDER_NAME.NETENT;
        case GAME_PROVIDER.EVOLUTION:
            return GAME_PROVIDER_NAME.EVOLUTION;
        case GAME_PROVIDER.EVERI:
            return GAME_PROVIDER_NAME.EVERI;
        case GAME_PROVIDER.NYX:
            return GAME_PROVIDER_NAME.NYX;
        case GAME_PROVIDER.IGT:
        case GAME_PROVIDER.RGS:
            return GAME_PROVIDER_NAME.IGT;
        case GAME_PROVIDER.SPIN:
            return GAME_PROVIDER_NAME.SPIN;
        case GAME_PROVIDER.EZUGI:
            return GAME_PROVIDER_NAME.EZUGI;
        case GAME_PROVIDER.HIFIVE:
            return GAME_PROVIDER_NAME.HIFIVE;
        case GAME_PROVIDER.REALMS:
            return GAME_PROVIDER_NAME.REALMS;
        case GAME_PROVIDER.INCENTIVE_GAMES:
            return GAME_PROVIDER_NAME.INCENTIVE_GAMES;
        case GAME_PROVIDER.DGC:
            return GAME_PROVIDER_NAME.DGC;
        case GAME_PROVIDER.ARISTOCRAT:
            return GAME_PROVIDER_NAME.ARISTOCRAT;
        case GAME_PROVIDER.PLAYTECH:
            return GAME_PROVIDER_NAME.PLAYTECH;
        case GAME_PROVIDER.PRAGMATIC:
            return GAME_PROVIDER.PRAGMATIC;
        case GAME_PROVIDER.AWAGER:
            return GAME_PROVIDER.AWAGER;
    }
};

//used by Evolution and NetEnt
const gameConfigHelper = data => {
    const region = getRegion().toUpperCase();

    return {
        App: {
            playMode: config.game.playMode,
            latestPlayed: null // not used yet
        },
        partnerWebKey: config.game.providers.RGS.url[region],
        state: region,
        ipAddress: data.ip,
        renderMode: 'Web',
        inApp: {
            is: true,
            platform: getGameSpecificOS()
        },
        currency: config.game.currency,
        lang: config.game.lang,
        countryCode: config.game.countryCode,
        deviceType: getGameSpecificChannel(),
        deviceOS: getGameSpecificOS(),
        appType: 'BROWSER', //todo: check to see what values this can have
        showGeoLocationSplashScreen: true,
        //todo: check what the hell webservicedomain is used for
        webserviceDomain: 'https://cert-rgs.gameaccount.com', //return "live" === e ? "https://gan-rgs.betfaircasino.com" : "cert" === e ? "https://cert-rgs.gameaccount.com" : "https://mt-rgs." + e + ".gameaccount.com"
        brandTheme: config.game.brandTheme
    };
};

export {
    gameConfigHelper,
    getGameSpecificChannel,
    getGameSpecificOS,
    getGameSpecificProvider,
    providerToGameSpecificProvider
};
