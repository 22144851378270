import { fetchLicenseFailed, licenseReceived } from '../geo-license'
import awAxios from '../../../../common/axios/AWAxios'

import { commonHeadersAW } from '../../../../common/commonHeaders'

import { getLicenseUrl } from '../../../../common/helpers/plc-helper'
import { FetchType, getRequestUid } from '../../../../common/helpers/fetch-helper'
import { fetchEnd, fetchError, fetchStart } from '../../../fetch/fetch'
import { datadogRum } from '@datadog/browser-rum'
import connectionType from '../../../../common/helpers/connectionType'

export const GEOCOMPLY_LICENSE_FETCH = 'GEOCOMPLY_LICENSE_FETCH'

// Reducer
export const reducer = state => {
    return {
        ...state,
        loading: true
    }
}

export const fetchLicense = () => async dispatch => {
    /* eslint-disable */
    console.info('fetchLicense()')

    console.warn('LICENSE FETCHING')
    const requestUid = getRequestUid(FetchType.License)
    const licenseUrl = getLicenseUrl()

    dispatch({ type: GEOCOMPLY_LICENSE_FETCH }) // could be removed i think
    dispatch(fetchStart(requestUid))

    try {
        const response = await awAxios.get(licenseUrl, { headers: commonHeadersAW })

        console.info('fetchLicense()', response?.data)

        const geoComplyLicense = response?.data?.geocomply_license[0]

        // TODO: Schedule to get a new the license, usualy expires in 24h
        dispatch(licenseReceived(geoComplyLicense))
        dispatch(fetchEnd(requestUid))
    } catch (err) {
        if (err.config?.headers) {
            delete err.config?.headers
        }
        datadogRum.addError(
            new Error(err),
            Object.assign(
                {
                    type: 'FETCH_LICENSE',
                    connectionType: connectionType()
                },
                err
            ),
            'custom'
        )

        dispatch(fetchLicenseFailed(err))
        dispatch(fetchError(requestUid))
    }
}
