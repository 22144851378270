import { datadogRum } from '@datadog/browser-rum';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { getGameSpecificProvider } from '../../../../common/helpers/gameConfigsHelper';
import {
    setGameLoadComplete,
    setSpinInProgress,
    setLoadingPercent,
    setAudioEnabled,
    setErrorMessageDisplayed,
    setInGameError
} from '../../../../redux/actions/game-window';
import { setGameClientStatus } from '../../../../redux/actions/gameClientStatus';

export const useGameClientHandlers = () => {
    const dispatch = useDispatch();
    const provider = getGameSpecificProvider()?.toUpperCase();

    const ddLogProviderError = useCallback(
        (payload: ProviderErrorPayload) => {
            datadogRum.addError(new Error('GW_IN_GAME_ERROR'), { provider, ...payload }, 'custom');
        },
        [provider]
    );

    const handleGameLoaded = useCallback(() => {
        dispatch(setGameLoadComplete(true));
    }, [dispatch]);

    // Handler to account for the handshake event sent by game-client. This will allow to send the launch params only when the client has finished mounting.
    const handleGameClientHandshake = () => {
        dispatch(setGameClientStatus(true));
    };

    const handleGameLoadStarted = useCallback(() => {
        dispatch(setGameLoadComplete(false));
    }, [dispatch]);

    const handleSpinInProgress = useCallback(
        (payload: boolean) => {
            dispatch(setSpinInProgress(payload));
        },
        [dispatch]
    );

    const handleLoadProgress = useCallback(
        (payload: number) => {
            dispatch(setLoadingPercent(payload));
        },
        [dispatch]
    );

    const handleAudioEnabled = useCallback(
        (payload: boolean) => {
            dispatch(setAudioEnabled(payload));
        },
        [dispatch]
    );

    const handleResetGame = useCallback(() => {
        window.location.reload();
    }, []);

    const handleProviderError = useCallback(
        (payload: ProviderErrorPayload) => {
            if (!payload.display) {
                datadogRum.addAction('GW_ERROR_POPUP_DISMISSED');
                return dispatch(setErrorMessageDisplayed(false));
            }

            dispatch(setErrorMessageDisplayed(true));
            dispatch(
                setInGameError({
                    errorCode: payload.errorCode,
                    errorMessage: payload.errorMessage,
                    errorType: payload.errorType,
                    errorParameters: payload?.errorParameters
                })
            );

            ddLogProviderError(payload);
        },
        [dispatch, ddLogProviderError]
    );

    return {
        handleGameClientHandshake,
        handleGameLoadStarted,
        handleLoadProgress,
        handleGameLoaded,
        handleResetGame,
        handleAudioEnabled,
        handleSpinInProgress,
        handleProviderError
    };
};

export type ProviderErrorPayload = {
    display: boolean | unknown;
    errorCode: string;
    errorMessage: string;
    errorType: string;
    errorParameters: Record<string, unknown>;
};
