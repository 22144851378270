import { PLATFORM } from '../../../../common/constants/game-constants';

import getBalanceDataWeb from './get-user-balance-web';

export const getUserBalanceByPlatform = {
    [PLATFORM.Web]: getBalanceDataWeb,
    [PLATFORM.Android]: getBalanceDataWeb,
    [PLATFORM.Xsell]: getBalanceDataWeb,
    [PLATFORM.Ios]: getBalanceDataWeb
};

/**
 * get common ui data by platform context
 *
 * @param {string} platform
 * @returns {(function(): function(*): Promise<void>)} get common ui data action creator
 */
export default function getUserBalanceFactory(platform) {
    return getUserBalanceByPlatform[platform];
}
