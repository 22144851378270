export interface ErrorPopupHandlers {
    exitGame: (redirectUrl?: string) => void;
    dismissErrorMessage: () => void;
    chooseDialog: () => void;
}

export const ERROR_TYPE = {
    GENERIC: 'catch_all',
    INSUFFICIENT_FUNDS: 'balance_too_low',
    SESSION_EXPIRED: 'session_expired',
    GAME_IN_PROGRESS: 'game_in_progress',
    GAME_STOPPED_WORKING: 'game_stopped_working',
    CONNECTION_LOST: 'connection_lost',
    TOO_MANY_GAMES_OPEN: 'too_many_games_open',
    UNKNOWN: 'unknown_error_nyx',
    FREE_SPIN_DIALOG_START: 'free_spin_dialog_start',
    FREE_SPIN_DIALOG_CHOOSE: 'free_spin_dialog_choose',
    FREE_SPIN_DIALOG_FINISH: 'free_spin_dialog_finish',
} as const;

export type GameErrorType = typeof ERROR_TYPE[keyof typeof ERROR_TYPE];

export const BUTTON_TYPE = {
    TRY_AGAIN: 'try_again',
    DEPOSIT: 'deposit',
    EXIT: 'exit',
    DISMISS: 'dismiss',
    SUPPORT: 'support',
    BACK_TO_LAST_GAME: 'back_to_last_game',
    FREE_SPIN_CHOOSE_FREE_SPIN : 'free_spin_choose_free_spin',
    FREE_SPIN_CHOOSE_REAL_MONEY : 'free_spin_choose_real_money',
    FREE_SPIN_FINISH_SPINS : 'free_spin_finish_spins',
} as const;

export const ERROR_MESSAGES = {
    [ERROR_TYPE.GENERIC]: {
        main: {
            errorMessage: "We're sorry about this. Please try again or leave your game to go home.",
            errorName: 'Something went wrong in the casino game'
        },
        secondary: {
            errorName: 'Leave your game to contact Support?',
            firstMessage: 'Need help? ',
            actionMessage: 'Contact our Support Team.'
        }
    },
    [ERROR_TYPE.INSUFFICIENT_FUNDS]: {
        main: {
            errorMessage: 'Please lower your bet amount or add additional funds to continue',
            errorName: 'Insufficient Balance'
        },
        secondary: {
            errorName: 'Leave your game to set deposit limits?',
            firstMessage: 'Also, you can manage your play by ',
            actionMessage: 'setting deposit limits.'
        }
    },
    [ERROR_TYPE.SESSION_EXPIRED]: {
        main: {
            errorMessage: 'Dismiss this message to re-launch the game.',
            errorName: 'Expired session'
        }
    },
    [ERROR_TYPE.GAME_IN_PROGRESS]: {
        main: {
            errorMessage: 'You have another game open. Please finish your last game before starting a new one.',
            errorName: 'Finish your last game',
            loadingMessage: 'Loading your last game...'
        }
    },
    [ERROR_TYPE.GAME_STOPPED_WORKING]: {
        main: {
            errorMessage: 'Sorry, we’re working on a fix. Please go back home and try again later.',
            errorName: 'Your game stopped loading'
        }
    },
    [ERROR_TYPE.CONNECTION_LOST]: {
        main: {
            errorMessage: 'It’s not you, it’s us. Please try again or go back home.',
            errorName: 'We lost connection'
        }
    },
    [ERROR_TYPE.TOO_MANY_GAMES_OPEN]: {
        main: {
            errorMessage: 'Please leave all of your games (on all devices) to start playing again.',
            errorName: 'Too many games open'
        }
    },
    [ERROR_TYPE.FREE_SPIN_DIALOG_START]: {
        main: {
            errorMessage: 'Choose Cash mode or Free Spins mode to play the game.',
            errorName: 'Choose one to continue'
        }
    },
    [ERROR_TYPE.FREE_SPIN_DIALOG_CHOOSE]: {
        main: {
            errorMessage: 'How would you like to use your Free Rounds?',
            errorName: 'Choose one to continue'
        }
    },
    [ERROR_TYPE.FREE_SPIN_DIALOG_FINISH]: {
        main: {
            errorMessage: 'Future rounds will be played in cash mode.',
            errorName: 'Free Spins'
        }
    },
    [ERROR_TYPE.UNKNOWN]: {
        main: {
            errorMessage: 'We’re sorry about this. You can continue playing now or go back home.',
            errorName: 'Something went wrong in the casino game'
        }
    },
} as const;

export const BUTTON_MESSAGES = {
    TRY_AGAIN: 'Try again',
    GO_HOME: 'Go to lobby',
    DEPOSIT: 'Add funds to balance',
    BACK_TO_GAME: 'Back to game',
    DISMISS: 'Dismiss',
    LEAVE_TO_SUPPORT: 'Yes, leave',
    BACK_TO_LAST_GAME: 'Back to last game',
    LEAVE_TO_GO_HOME: 'Leave to go home',
    CONTINUE_PLAYING: 'Continue playing',
    FREE_SPIN_CHOOSE_FREE_SPIN : 'Free Spins mode',
    FREE_SPIN_CHOOSE_REAL_MONEY : 'Cash mode',
    FREE_SPIN_FINISH_SPINS : 'Play for Real',
} as const;

export type ButtonType = typeof BUTTON_TYPE[keyof typeof BUTTON_TYPE];

interface Error {
    mainProps: {
        mainButton: ButtonType;
        mainButtonMessage: string;
        secondaryButton?: ButtonType;
        secondaryButtonMessage?: string;
        errorMessage: string;
        errorName: string;
    };
    errorType: GameErrorType;
}

export type GameErrors = {
    [key in GameErrorType]: Error;
};

export const GAME_ERRORS: GameErrors = {
    [ERROR_TYPE.GENERIC]: {
        mainProps: {
            mainButton: BUTTON_TYPE.TRY_AGAIN,
            mainButtonMessage: BUTTON_MESSAGES.TRY_AGAIN,
            secondaryButton: BUTTON_TYPE.EXIT,
            secondaryButtonMessage: BUTTON_MESSAGES.GO_HOME,
            errorMessage: ERROR_MESSAGES[ERROR_TYPE.GENERIC].main.errorMessage,
            errorName: ERROR_MESSAGES[ERROR_TYPE.GENERIC].main.errorName
        },
        errorType: ERROR_TYPE.GENERIC
    },
    [ERROR_TYPE.INSUFFICIENT_FUNDS]: {
        mainProps: {
            mainButton: BUTTON_TYPE.DEPOSIT,
            mainButtonMessage: BUTTON_MESSAGES.DEPOSIT,
            secondaryButton: BUTTON_TYPE.DISMISS,
            secondaryButtonMessage: BUTTON_MESSAGES.BACK_TO_GAME,
            errorMessage: ERROR_MESSAGES[ERROR_TYPE.INSUFFICIENT_FUNDS].main.errorMessage,
            errorName: ERROR_MESSAGES[ERROR_TYPE.INSUFFICIENT_FUNDS].main.errorName
        },
        errorType: ERROR_TYPE.INSUFFICIENT_FUNDS
    },
    [ERROR_TYPE.SESSION_EXPIRED]: {
        mainProps: {
            mainButton: BUTTON_TYPE.TRY_AGAIN,
            mainButtonMessage: BUTTON_MESSAGES.DISMISS,
            secondaryButton: BUTTON_TYPE.EXIT,
            secondaryButtonMessage: BUTTON_MESSAGES.GO_HOME,
            errorMessage: ERROR_MESSAGES[ERROR_TYPE.SESSION_EXPIRED].main.errorMessage,
            errorName: ERROR_MESSAGES[ERROR_TYPE.SESSION_EXPIRED].main.errorName
        },
        errorType: ERROR_TYPE.SESSION_EXPIRED
    },
    [ERROR_TYPE.GAME_IN_PROGRESS]: {
        mainProps: {
            mainButton: BUTTON_TYPE.BACK_TO_LAST_GAME,
            mainButtonMessage: BUTTON_MESSAGES.BACK_TO_LAST_GAME,
            errorMessage: ERROR_MESSAGES[ERROR_TYPE.GAME_IN_PROGRESS].main.errorMessage,
            errorName: ERROR_MESSAGES[ERROR_TYPE.GAME_IN_PROGRESS].main.errorName
        },
        errorType: ERROR_TYPE.GAME_IN_PROGRESS
    },
    [ERROR_TYPE.GAME_STOPPED_WORKING]: {
        mainProps: {
            mainButton: BUTTON_TYPE.EXIT,
            mainButtonMessage: BUTTON_MESSAGES.LEAVE_TO_GO_HOME,
            secondaryButton: BUTTON_TYPE.TRY_AGAIN,
            secondaryButtonMessage: BUTTON_MESSAGES.TRY_AGAIN,
            errorMessage: ERROR_MESSAGES[ERROR_TYPE.GAME_STOPPED_WORKING].main.errorMessage,
            errorName: ERROR_MESSAGES[ERROR_TYPE.GAME_STOPPED_WORKING].main.errorName
        },
        errorType: ERROR_TYPE.GAME_STOPPED_WORKING
    },
    [ERROR_TYPE.CONNECTION_LOST]: {
        mainProps: {
            mainButton: BUTTON_TYPE.TRY_AGAIN,
            mainButtonMessage: BUTTON_MESSAGES.TRY_AGAIN,
            secondaryButton: BUTTON_TYPE.EXIT,
            secondaryButtonMessage: BUTTON_MESSAGES.LEAVE_TO_GO_HOME,
            errorMessage: ERROR_MESSAGES[ERROR_TYPE.CONNECTION_LOST].main.errorMessage,
            errorName: ERROR_MESSAGES[ERROR_TYPE.CONNECTION_LOST].main.errorName
        },
        errorType: ERROR_TYPE.CONNECTION_LOST
    },
    [ERROR_TYPE.TOO_MANY_GAMES_OPEN]: {
        mainProps: {
            mainButton: BUTTON_TYPE.EXIT,
            mainButtonMessage: BUTTON_MESSAGES.LEAVE_TO_GO_HOME,
            errorMessage: ERROR_MESSAGES[ERROR_TYPE.TOO_MANY_GAMES_OPEN].main.errorMessage,
            errorName: ERROR_MESSAGES[ERROR_TYPE.TOO_MANY_GAMES_OPEN].main.errorName
        },
        errorType: ERROR_TYPE.TOO_MANY_GAMES_OPEN
    },
    [ERROR_TYPE.FREE_SPIN_DIALOG_START]: {
        mainProps: {
            mainButton: BUTTON_TYPE.FREE_SPIN_CHOOSE_FREE_SPIN,
            mainButtonMessage: BUTTON_MESSAGES.FREE_SPIN_CHOOSE_FREE_SPIN,
            secondaryButton: BUTTON_TYPE.FREE_SPIN_CHOOSE_REAL_MONEY,
            secondaryButtonMessage: BUTTON_MESSAGES.FREE_SPIN_CHOOSE_REAL_MONEY,
            errorMessage: ERROR_MESSAGES[ERROR_TYPE.FREE_SPIN_DIALOG_START].main.errorMessage,
            errorName: ERROR_MESSAGES[ERROR_TYPE.FREE_SPIN_DIALOG_START].main.errorName
        },
        errorType: ERROR_TYPE.FREE_SPIN_DIALOG_START
    },
    [ERROR_TYPE.FREE_SPIN_DIALOG_CHOOSE]: {
        mainProps: {
            mainButton: null,
            mainButtonMessage: null,
            errorMessage: ERROR_MESSAGES[ERROR_TYPE.FREE_SPIN_DIALOG_CHOOSE].main.errorMessage,
            errorName: ERROR_MESSAGES[ERROR_TYPE.FREE_SPIN_DIALOG_CHOOSE].main.errorName
        },
        errorType: ERROR_TYPE.FREE_SPIN_DIALOG_CHOOSE
    },
    [ERROR_TYPE.FREE_SPIN_DIALOG_FINISH]: {
        mainProps: {
            mainButton: BUTTON_TYPE.FREE_SPIN_FINISH_SPINS,
            mainButtonMessage: BUTTON_MESSAGES.FREE_SPIN_FINISH_SPINS,
            errorMessage: ERROR_MESSAGES[ERROR_TYPE.FREE_SPIN_DIALOG_FINISH].main.errorMessage,
            errorName: ERROR_MESSAGES[ERROR_TYPE.FREE_SPIN_DIALOG_FINISH].main.errorName
        },
        errorType: ERROR_TYPE.FREE_SPIN_DIALOG_START
    },
    [ERROR_TYPE.UNKNOWN]: {
        mainProps: {
            mainButton: BUTTON_TYPE.DISMISS,
            mainButtonMessage: BUTTON_MESSAGES.CONTINUE_PLAYING,
            secondaryButton: BUTTON_TYPE.EXIT,
            secondaryButtonMessage: BUTTON_MESSAGES.LEAVE_TO_GO_HOME,
            errorMessage: ERROR_MESSAGES[ERROR_TYPE.UNKNOWN].main.errorMessage,
            errorName: ERROR_MESSAGES[ERROR_TYPE.UNKNOWN].main.errorName
        },
        errorType: ERROR_TYPE.UNKNOWN
    },
};

export const FOOTER_TYPES = {
    [ERROR_TYPE.INSUFFICIENT_FUNDS]: {
        props: {
            mainButton: BUTTON_TYPE.SUPPORT,
            mainButtonMessage: BUTTON_MESSAGES.LEAVE_TO_SUPPORT,
            secondaryButton: BUTTON_TYPE.DISMISS,
            secondaryButtonMessage: BUTTON_MESSAGES.BACK_TO_GAME,
            errorName: ERROR_MESSAGES[ERROR_TYPE.INSUFFICIENT_FUNDS].secondary.errorName
        },
        messages: {
            firstMessage: ERROR_MESSAGES[ERROR_TYPE.INSUFFICIENT_FUNDS].secondary.firstMessage,
            actionMessage: ERROR_MESSAGES[ERROR_TYPE.INSUFFICIENT_FUNDS].secondary.actionMessage
        }
    },
    [ERROR_TYPE.GENERIC]: {
        props: {
            mainButton: BUTTON_TYPE.SUPPORT,
            mainButtonMessage: BUTTON_MESSAGES.LEAVE_TO_SUPPORT,
            secondaryButton: BUTTON_TYPE.TRY_AGAIN,
            secondaryButtonMessage: BUTTON_MESSAGES.BACK_TO_GAME,
            errorName: ERROR_MESSAGES[ERROR_TYPE.GENERIC].secondary.errorName
        },
        messages: {
            firstMessage: ERROR_MESSAGES[ERROR_TYPE.GENERIC].secondary.firstMessage,
            actionMessage: ERROR_MESSAGES[ERROR_TYPE.GENERIC].secondary.actionMessage
        }
    }
};

export const ERROR_CTA_EVENTS = {
    TRY_AGAIN: 'try_again',
    ADD_FUNDS: 'add_funds',
    EXIT_TOLOBBY: 'exit_to_lobby',
    DISMISS: 'back_to_game',
    CUSTOMER_SUPPORT: 'contact_our_support_team',
    SET_DEPOSIT_LIMIT: 'setting_deposit_limits',
    LEAVE: 'yes_leave'
} as const;

export type CtaEventsType = typeof ERROR_CTA_EVENTS[keyof typeof ERROR_CTA_EVENTS];

export const getErrorType = (type: string): Error => {
    return GAME_ERRORS[type] ? GAME_ERRORS[type] : GAME_ERRORS[ERROR_TYPE.GENERIC];
};
