import { PLATFORM } from '../../constants/game-constants'
import VoidService from '../../helpers/void-service'

import useGeolocationTriggerAndroid from './use-geolocation-trigger-android'

export const useGeolocationTriggerByPlatformMap = {
    [PLATFORM.Web]: VoidService,
    [PLATFORM.Android]: useGeolocationTriggerAndroid,
    [PLATFORM.Xsell]: VoidService,
    [PLATFORM.Ios]: VoidService
}

/**
 * useGeolocationTrigger factory by platform context
 *
 * @param {string} platform
 * @returns {function} current useGeolocationTrigger hook
 */
export default function useGeolocationTriggerFactory(platform) {
    return useGeolocationTriggerByPlatformMap[platform]
}
