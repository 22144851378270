import { types } from '../../types';

export const setSWJJackpotDataReducer = (state = {}, action) => {
    if (action.type === types.SET_SWJ_JACKPOT_DATA) {
        return {
            swjJackpotData: action?.payload
        };
    }
    return state;
};
