import { useDispatch, useSelector } from 'react-redux';
import {
    brandSelector,
    gameIdSelector,
    gameNameSelector,
    gameUidSelector,
    ganIdSelector,
    providerRefSelector,
    regionSelector,
    rgiSelector,
    nativeIdSelector,
    directLaunchParamsSelector
} from '../../../selectors/gameContext';
import { useGameClientMessageHandler } from './hooks/useGameClientMessageHandler';
import { useAppSelector } from '../../../redux/hooks';
import { mapProvider } from './mapProvider';
import { useEffect } from 'preact/hooks';
import sendTrendingGames from '../../../redux/actions/trending-games/trending-games';
import EmptyGame from '../EmptyGame/EmptyGame';
import { clientReadySelector } from '../../../selectors/gameClientStatus';
import { useMemo } from 'react';
import { getXsellGeocomplyCookie } from '../../../common/helpers/cookies';
import { experimentsLoadedSelector, experimentsSelector } from '../../../selectors/amplitude-experiments';
import { isIosWrapper } from '../../../common/wrapper-bridge-mobile';

const GameClient = ({ provider }: { provider?: string }) => {
    useGameClientMessageHandler(provider);
    const dispatch = useDispatch();

    const clientReady = useSelector(clientReadySelector);
    const experimentsReady = useSelector(experimentsLoadedSelector);

    const clientBaseUrl = window.location.hostname.replace('launcher', 'gc');

    const clientUrl = `https://${clientBaseUrl}${process.env.NODE_CONFIG_ENV === 'local-development' ? ':5173' : ''}/`;

    const rgi = useSelector(rgiSelector);
    const brand = useSelector(brandSelector);
    const gameName = useSelector(gameNameSelector);
    const providerRef = useSelector(providerRefSelector);
    const ganId = useSelector(ganIdSelector);
    const region = useSelector(regionSelector);
    const gameUid = useSelector(gameUidSelector);
    const gameId = useSelector(gameIdSelector);
    const nativeId = useSelector(nativeIdSelector);
    const userId = useAppSelector(state => state?.session?.userId);
    const amplitudeExperiments = useSelector(experimentsSelector);

    const directLaunchParams = useSelector(directLaunchParamsSelector);
    const xsellGeocomplyToken = getXsellGeocomplyCookie();

    const shouldRespondToHandshake = useMemo(() => {
        if (isIosWrapper()) {
            return clientReady;
        }
        return clientReady && experimentsReady;
    }, [clientReady, experimentsReady]);

    const mappedProvider = mapProvider(provider, rgi);

    const gameUrl = new URL(clientUrl);

    const initializeGameParams = useMemo(
        () => ({
            rgi,
            brand,
            gameName,
            provider: mappedProvider,
            providerRef,
            ganId,
            region,
            gameUid,
            userId,
            ...(xsellGeocomplyToken && { geocomplyToken: xsellGeocomplyToken }),
            ...(directLaunchParams && { directLaunchParams })
        }),
        [
            rgi,
            brand,
            gameName,
            mappedProvider,
            providerRef,
            ganId,
            region,
            gameUid,
            userId,
            xsellGeocomplyToken,
            directLaunchParams
        ]
    );

    useEffect(() => {
        if (!shouldRespondToHandshake) {
            return;
        }
        const gameClientFrame = document.querySelector<HTMLIFrameElement>('#casino-game-client');
        gameClientFrame.contentWindow.postMessage(
            {
                type: 'GL/INITIALIZE_HANDSHAKE_PARAMS',
                payload: {
                    initializeGameParams,
                    amplitudeEvents: { geocomplyEnabled: amplitudeExperiments?.geocomplyEnabled }
                }
            },
            '*'
        );
    }, [initializeGameParams, shouldRespondToHandshake]);

    useEffect(() => {
        if (gameId && userId) {
            dispatch(sendTrendingGames());
        }
    }, [dispatch, gameId, userId]);

    if (nativeId === '-1') {
        return <EmptyGame />;
    }

    return rgi && brand && gameName && mappedProvider && providerRef && ganId && region && gameUid && userId ? (
        <iframe style={{ width: '100%', height: '100%' }} src={gameUrl.href} title={gameName} id="casino-game-client" />
    ) : null;
};

export default GameClient;
