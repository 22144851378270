import { PLATFORM } from '../../../../common/constants/game-constants';
import AsyncVoidService from '../../../../common/helpers/async-void-service';
import getServerTimeWeb from './get-server-time.web';

export const serverTimeByPlatformMap = {
    [PLATFORM.Web]: getServerTimeWeb,
    [PLATFORM.Android]: getServerTimeWeb,
    [PLATFORM.Xsell]: getServerTimeWeb,
    [PLATFORM.Ios]: AsyncVoidService
};

/**
 * get current server time by platform context
 *
 * @param {string} platform
 * @returns {(function(timezone: string): function(*): Promise<void>)} get current session details action creator
 */
export default function getServerTimeFactory(platform) {
    return serverTimeByPlatformMap[platform];
}
