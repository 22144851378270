import { useMemo } from 'preact/hooks';
import { useSelector } from 'react-redux';
import { ButtonType, BUTTON_TYPE, ERROR_TYPE } from '../../../game-window/contants/errors';
import { depositLimitsLinkSelector, inGameErrorSelector, supportLinkSelector } from '../../../selectors/game-window';
import { BackToLastGameButton } from './BackToLastGameButton';
import { ContactSupportButton } from './ContactSupportButton';
import { GenericCancelButton } from './GenericCancelButton';
import { GenericDepositButton } from './GenericDepositButton';
import { GenericDismissButton } from './GenericDismissButton';
import { GenericExitButton } from './GenericExitButton';

export const getButton = (type: ButtonType, message: string, supportUrl, handlers, buttonStyle: string) => {
    const { dismissErrorMessage, exitGame, chooseDialog } = handlers;
    switch (type) {
        case BUTTON_TYPE.DEPOSIT:
            return (
                <GenericDepositButton
                    handleDismiss={dismissErrorMessage}
                    buttonMessage={message}
                    buttonStyle={buttonStyle}
                />
            );
        case BUTTON_TYPE.TRY_AGAIN:
            return <GenericCancelButton buttonMessage={message} buttonStyle={buttonStyle} />;
        case BUTTON_TYPE.EXIT:
            return <GenericExitButton exitGame={exitGame} buttonMessage={message} buttonStyle={buttonStyle} />;
        case BUTTON_TYPE.DISMISS:
            return (
                <GenericDismissButton
                    handleDismiss={dismissErrorMessage}
                    buttonMessage={message}
                    buttonStyle={buttonStyle}
                />
            );
        case BUTTON_TYPE.SUPPORT:
            return (
                <ContactSupportButton
                    exitGame={exitGame}
                    buttonMessage={message}
                    supportUrl={supportUrl}
                    buttonStyle={buttonStyle}
                />
            );
        case BUTTON_TYPE.BACK_TO_LAST_GAME:
            return (
                <BackToLastGameButton
                    handleDismiss={dismissErrorMessage}
                    buttonMessage={message}
                    buttonStyle={buttonStyle}
                />
            );
        case BUTTON_TYPE.FREE_SPIN_CHOOSE_FREE_SPIN:
            return <GenericExitButton exitGame={() => chooseDialog(1)} buttonMessage={message} buttonStyle={buttonStyle} />;
        case BUTTON_TYPE.FREE_SPIN_CHOOSE_REAL_MONEY:
            return <GenericExitButton exitGame={() => chooseDialog(0)} buttonMessage={message} buttonStyle={buttonStyle} />;
        case BUTTON_TYPE.FREE_SPIN_FINISH_SPINS:
            return <GenericExitButton exitGame={() => chooseDialog(0)} buttonMessage={message} buttonStyle={buttonStyle} />;
        default:
            return <></>;
    }
};

export const GenericPopupBody = ({ handlers, bodyProps, iconError, errorParameters }) => {
    const {
        errorName,
        errorMessage,
        mainButton,
        secondaryButton,
        mainButtonMessage,
        secondaryButtonMessage
    } = bodyProps;
    
    const { errorType } = useSelector(inGameErrorSelector);
    const supportLink = useSelector(supportLinkSelector);
    const depositLimitsLink = useSelector(depositLimitsLinkSelector);
    const supportUrl = useMemo(() => (errorType === ERROR_TYPE.INSUFFICIENT_FUNDS ? depositLimitsLink : supportLink), [
        depositLimitsLink,
        supportLink,
        errorType
    ]);

    return (
        <div className={`generic-popup-main-window`}>
            <div className="error-icon">{iconError}</div>
            <div className="generic-popup-wrapper">
                <div className="generic-popup-title">
                    <p className="error-title">{errorName}</p>
                </div>
                {errorMessage && (
                    <div className={`generic-popup-message`}>
                        <p className="error-message">{errorMessage}</p>
                    </div>
                )}
            </div>
            <div className="generic-popup-actions">
                {getButton(mainButton, mainButtonMessage, supportUrl, handlers, 'main')}
                {secondaryButton &&
                    getButton(secondaryButton, secondaryButtonMessage, supportUrl, handlers, 'secondary')}
                {errorType === ERROR_TYPE.FREE_SPIN_DIALOG_CHOOSE && 
                    errorParameters?.reverse().map((buttonText, index) => {
                        return <GenericExitButton key={index} exitGame={() => handlers.chooseDialog(errorParameters.length - 1 - index)} buttonMessage={buttonText} buttonStyle={'main'} />;
                    })}
            </div>
        </div>
    );
};
