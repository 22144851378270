import { createPortal } from 'react-dom';
require(`./styles/${process.env.APPLICATION}/style.scss`);

export const TCPopup = ({toggleOptIn, userOptedIn, setShowTCPopup, renderLoadingClass}) => {
    //from prismic
    const jackpotRulesURL = '';
    const optedInOption = userOptedIn > 0 ? 'in' : 'out';
    const tcDescription = 'By opting into the Jackpot, each round, an extra $0.25 Jackpot Wager will be deducted from your account balance in addition to your main game wager. The total bet value displayed within a game window only corresponds to the main game wager and does not include your Jackpot Wager. '

    const handleOptInButton = () => {
        toggleOptIn();
    };

    return (
        <>
            {createPortal(
                <div className="tc-background" onClick={() => setShowTCPopup(false)} />,
                document.getElementById("game-launcher-wrapper")
            )}
            <div className="tc-wrapper">
                <div className="header">
                    <div className="jackpots-chyron" />
                </div>

                <div className="content">
                    <span className="title">Opt-in, and on every spin you could win a jackpot</span>
                    <div className="description">
                        {tcDescription}
                        <a href={jackpotRulesURL}> Jackpot Rules</a>
                    </div>
                </div>

                <div className={`footer ${renderLoadingClass()}`}>
                    <div className="button-field">
                        <span className={`text-opted-${optedInOption}`}>{`You're opted ${optedInOption}`}</span>
                            <input type="checkbox" id="switch-button" checked={userOptedIn} onChange={handleOptInButton} />
                            <label for="switch-button" />
                    </div>
                </div>
            </div>
        </>
    );
}
