import { memo, useState } from 'react';
import { createPortal } from 'react-dom';
import { SiteWideJackpotWinJackpot } from './SiteWideJackpot.debug';
import { getEnvironment } from '../../common/hooks/use-geocomply-flow/use-geocomply-helpers';
import './default.scss';


export const DebugMenu = memo(({setShowJackpotWinScreen, setJackpotType}) => {
    const [showDebugMenu, setShowDebugMenu]= useState(false);
    const environment = getEnvironment()?.type

    return (
        environment === 'cert' && createPortal(
            showDebugMenu
            ? <div className="debug-menu-wrapper">
                <button className="debug-menu-toggle" onClick={() => setShowDebugMenu(false)}>x</button>
                <h2>Debug menu</h2>
                <SiteWideJackpotWinJackpot
                    jackpotType={'mini'}
                    setJackpotType={setJackpotType}
                    setShowJackpotWinScreen={setShowJackpotWinScreen}
                />
                 <SiteWideJackpotWinJackpot
                    jackpotType={'minor'}
                    setJackpotType={setJackpotType}
                    setShowJackpotWinScreen={setShowJackpotWinScreen}
                />
                 <SiteWideJackpotWinJackpot
                    jackpotType={'major'}
                    setJackpotType={setJackpotType}
                    setShowJackpotWinScreen={setShowJackpotWinScreen}
                />
                 <SiteWideJackpotWinJackpot
                    jackpotType={'mega'}
                    setJackpotType={setJackpotType}
                    setShowJackpotWinScreen={setShowJackpotWinScreen}
                />

                {/* <button className="button-kill-aggregation">KILL AGGREGATION</button> */}
            </div>
            : <button className="debug-menu-toggle" onClick={() => setShowDebugMenu(true)}>open debug menu</button>,
            document.body
        )
    );
});
