import { types } from '../../types';

const initialState = {
    clientReady: false
};

export const gameGameClientStatusReducer = (state = initialState, action) => {
    if (action.type === types.GAME_CLIENT_STATUS) {
        return {
            ...state,
            clientReady: action.payload
        };
    }

    return state;
};
