import { h } from 'preact';
import { LOADING_THEMES } from './types';
import { GenericLoadingScreen } from './components/GenericLoadingScreen';
import { CustomLoadingScreen } from './components/CustomLoadingScreen';
import { useSelector } from 'react-redux';
import { useLoadingStates } from './helpers/useLoadingStates';
import { loadingDataSelector } from '../../../selectors/game-window';
import { useState, useEffect } from 'react';


export const LoadingContainer = () => {
    require(`./styles/default.scss`);

    const loadingScreenVisible = useSelector(state => state?.['gameWindow']?.loadingScreenVisible);
    const customLoading = useSelector(loadingDataSelector);

    const { counter, loadingText } = useLoadingStates();

    if (!loadingScreenVisible) {
        return null;
    }

    const themeMap: Map<LOADING_THEMES, string> = new Map([
        ['Default', 'default'],
        ['Willy Wonka full, coming soon', 'willy_wonka full soon'],
        ['Willy Wonka full, available now', 'willy_wonka full available'],
        ['Willy Wonka bottom, coming soon', 'willy_wonka bottom soon'],
        ['Willy Wonka bottom, available now', 'willy_wonka bottom available'],
    ]);

    const [customTheme, setCustomTheme] = useState({
        themeName: 'default',
        themeClasses: ['default'],
    });

   useEffect(() => {
        if (customLoading?.data) {
            const chosenTheme = themeMap.get(customLoading?.data?.loading_theme);
            if (!chosenTheme) { 
                return;
            }
            const [themeName, ...themeClasses] = chosenTheme.split(' ');

            setCustomTheme({
                themeName,
                themeClasses,
            });
        }
    }, [customLoading]);

    return (
        <>
            <GenericLoadingScreen
                ready={!!customLoading}
                counter={counter}
                loadingText={loadingText}
                theme={customLoading?.['data']}
            />
            {customTheme.themeName === 'willy_wonka' && (
                <CustomLoadingScreen
                    counter={counter}
                    loadingText={loadingText}
                />
            )}            
        </>
    );
};
