import { GameWindowContext } from "../../../components/GameWindow/GameWindow";
import { useContext } from "preact/hooks";

const LogoIcon = props => {
    const isPortrait = props.isPortrait ? true : false;
    const isMenuOpen = props.isMenuOpen ? true : false;
    const isSwjEnabled = props.isSwjEnabled ? true : false;
    const marginBottom = 5
    const mohegansunIconScale = 0.7

    const centerStylingOfIcon = {
        position: 'absolute',
        left: '50vw',
        transform: 'translateX(-50%)'
    }

    const swjStylingofIcon = {

    }

    const fanduel = (
        <svg
            className="fanduel"
            width="1.875rem"
            height="1.875rem"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={ !isPortrait ?
                 { marginBottom: `${marginBottom}px` }
                 : (!isMenuOpen ?
                    (!isSwjEnabled ?
                        centerStylingOfIcon
                        : swjStylingofIcon
                    )
                    : {display: 'none'})
            }
            {...props}
        >
            <path
                d="M6.05924 10.7523C7.92878 9.88461 9.89899 9.3573 11.8356 9.12721C12.0466 9.09844 12.1329 9.24226 12.1089 9.41003C11.8644 10.8961 11.6966 12.4061 11.6343 13.9305C11.6199 14.1031 11.5241 14.2181 11.3323 14.2565C10.2585 14.4674 9.2087 14.7982 8.05342 15.3255C9.09365 17.1327 10.3496 18.7865 11.735 20.1719C11.8932 20.3397 11.989 20.5122 12.0322 20.7951C12.3917 23.1152 13.0197 25.4545 13.8586 27.6692C13.9113 27.7939 13.9113 27.8802 13.8346 27.9521C13.7531 28.024 13.6333 28.024 13.5182 27.9521C5.14364 22.8468 0.0862866 13.6956 0 4.10341C0 3.87331 0.0814929 3.70074 0.273241 3.59048C4.72179 1.09776 9.72642 -0.100665 14.7502 0.00479594C14.9755 0.0143833 15.0522 0.206131 14.9659 0.388292C14.2708 1.8264 13.6956 3.29327 13.2402 4.75535C13.1875 4.92793 13.082 5.01421 12.8951 5.0238C10.2106 5.16282 7.59322 5.76682 5.19637 6.76391C5.36894 8.13012 5.66615 9.46277 6.05444 10.7523H6.05924ZM15.335 5.03818C15.1673 5.0238 15.0522 4.8704 15.1049 4.70742C15.6323 3.17822 16.2363 1.73053 16.9266 0.388292C17.008 0.2301 17.1423 0.162988 17.3292 0.186957C21.1115 0.613596 24.6923 1.80243 27.885 3.58569C28.0815 3.69594 28.1678 3.86852 28.1582 4.09862C28.0911 13.1827 23.5419 21.8784 15.8815 27.1323C15.7377 27.233 15.5508 27.1755 15.4836 27.0077C14.9276 25.5121 14.4722 23.9973 14.1462 22.5304C14.1031 22.3338 14.1462 22.1852 14.2996 22.0702C18.9351 18.4174 22.1852 12.967 22.9666 6.76391C20.6033 5.79079 18.0483 5.18199 15.335 5.03818Z"
                fill="white"
            />
        </svg>
    );

    const mohegansun = (
        <svg
            className="mohegansun"
            width="2.82rem"
            height="2.82rem"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={ !isPortrait ? { marginBottom: `${marginBottom}px` } : (!isMenuOpen ? centerStylingOfIcon : {display: 'none'})}
            {...props}
        >
            <g
            style={ isPortrait && {  transform:`scale(${mohegansunIconScale})` , 'transform-origin':"center"} }



            >
                <path
                    d="m30.335 22.869-1.23-2.705c0-.123-.245-.246-.245-.369h-.123l-.492-.246-.86-.246a.738.738 0 0 0-.492.738c0 .492.246.492.246.983 0 .492-.123.37-.123.861v2.705c0 .492.123.246.123.738h.737l.861-.615.369-.246 1.106-1.352a.49.49 0 0 0 .123-.37"
                    fill="#FFD851"
                />
                <path
                    d="M15.335 15.86v.493h.123v.245h1.598a.122.122 0 0 0 .123-.122c.123 0 .123-.123.123-.246h.123v-.246c0-.123.123-.123.123-.246v-.246h-.123c0-.123 0-.246-.123-.246a.123.123 0 0 0-.123-.123l-.246-.246h-1.23l-.245.246a.123.123 0 0 1-.123.123v.615ZM29.23 15.86v.492a.123.123 0 0 0 .122.123l.246.246h1.476v-.246c0-.123.122-.123.122-.245a.369.369 0 0 0 .123-.246v-.246a.368.368 0 0 0-.122-.246c0-.123-.123-.123-.123-.246h-1.23c0 .123-.123.123-.246.123h-.492c0 .123-.123.123-.123.246v.246M15.335 29.262c0 .123 0 .246.123.246v.246c.123.123.123.123.123.246h.123l.246.246h1.106c0-.123.123-.123.123-.246h.37c0-.123 0-.123.122-.246v-.492h.123a.369.369 0 0 1-.123-.245c-.123-.123-.123-.123-.123-.246h-.123l-.246-.246h-1.23l-.245.246a.123.123 0 0 1-.123.122c0 .123-.123.123-.123.246v.246h-.123M29.106 29.385v.246c0 .123.123.123.123.246a.369.369 0 0 0 .123.246.123.123 0 0 1 .123.123h1.107a.123.123 0 0 0 .123-.123h.615v-.984h-.123c0-.122 0-.122-.123-.245v-.246h-.492a.123.123 0 0 0-.123-.123h-.86c0 .123-.124.123-.124.246a.123.123 0 0 0-.123.122c-.123 0-.123.123-.123.246a.37.37 0 0 0-.123.246Z"
                    fill="#fff"
                />
                <path
                    d="M20.622 19.058c.492-.123.492 0 .984.122h2.704c.37 0 .37.246.861.246h1.23v-1.475c-.123-.246-.123-.369-.246-.492l-.615-.492-.369-.368-.86-.615c-.7-.028-1.4.013-2.09.123h-.37l-.86 1.23c0 .122-.123.245-.123.368l-.369.984c0 .245.123.369.246.245M26.277 26.311c-.491-.122-.491.123-.983.123s-.369-.245-.86-.245h-1.845c-.369 0-.369.245-.86.122h-.861c-.37 0-.492-.122-.615.123v.37l.615.86a.983.983 0 0 0 .246.492l1.106.86h.984l2.336-1.106.369-.246.245-.369v-.984M20.008 24.467v-.983c0-.37-.123-.37-.123-.861v-.86c0-.493-.123-.493-.123-.984 0-.492-.246-.37-.123-.86.123-.493.123-.37-.246-.493H18.9c-.123 0-.369.123-.369.246l-.615.615c0 .123-.246.246-.246.369l-.614.86c-.025.7.016 1.4.123 2.09 0 .123 0 .37.122.37l1.23.86h.369l1.107.369v-.738Z"
                    fill="#FFD851"
                />
                <path
                    d="M24.68 8.484c.18-.476.303-.971.368-1.476 0-.492-.246-.983-.246-1.352 0-.37.123-.984.123-1.353l-.369-1.23a3.569 3.569 0 0 0-.245-1.106c-.123-.246-.37-.492-.37-.737V.492l-.245-.246h-.123a.123.123 0 0 0-.246 0h-.123a.49.49 0 0 1-.123.369v.492a.983.983 0 0 0-.246.614.123.123 0 0 1-.123.123v.246c-.123.369-.123.738-.246 1.107a4.18 4.18 0 0 0-.123 1.23l-.369 1.352c0 .369-.122.86-.122 1.352 0 .492.245.984.245 1.476-.02.454.022.909.123 1.352.123.369 0 .86.123 1.23.123.368.37.614.492.983-.02.292.022.586.123.86 0 .247.123.493.123.739l.369.245c0-.123.246-.123.369-.368.049-.217.132-.425.246-.615-.02-.293.022-.586.123-.86 0-.37.369-.616.491-.985.024-.419.106-.833.246-1.229 0-.492-.246-.984-.246-1.352v-.123ZM23.572.123V0v.123Z"
                    fill="#0073A5"
                />
                <path
                    d="M14.966 22.377h-.245l-.492-.246h-.615c-.246 0-.369-.246-.738-.369l-.983-.246-1.23-.246-1.352-.491-.738.245h-.492c-.372.083-.761.04-1.106-.123h-.492c-.492 0-.86.37-1.353.37H3.9l-.737.245h-.737l-1.353.37H.827v.245h.369l.369.246h.738l.614.369.984.246 1.23.368h4.18c.464-.065.919-.188 1.352-.368l1.107-.246c.369-.123.86.123 1.23 0h.122l.86-.37h.984ZM25.294 22.623c0-.123-.123-.246-.123-.369a1.72 1.72 0 0 0-.246-.738h-.86l-.615-.737h-.861c-.123 0-.246 0-.246.122l-.246.37a.246.246 0 0 0-.246.245l-.369.492c0 .123.123.984.123 1.107s0 .245.123.245h.123a1.229 1.229 0 0 0 .86.492h.37a.244.244 0 0 0 .285.062.245.245 0 0 0 .084-.062h.369l.491-.246.246-.246.369-.368h.123a.369.369 0 0 0 .123-.246c.123-.123.123-.123.123-.246"
                    fill="#BC2B30"
                />
                <path
                    d="M21.974 14.14a4.916 4.916 0 0 0 0-1.353 5.284 5.284 0 0 0-.246-1.353 4.304 4.304 0 0 0-.368-1.352 12.776 12.776 0 0 0-.492-1.23l-.246-.368c-.123-.123-.123-.37-.246-.37s-.246 0-.369.124c-.123.123-.123.246-.246.369h-.369c0 .123-.245.245-.245.368v.492c0 .123.245.246.245.37 0 .122.37.614.492.983.123.368 0 .983.123 1.352.123.369.492.492.615.86.066.292.148.58.246.861v.246a.738.738 0 0 0 .123.492h.614c.123 0 .123-.123.246-.246h.123v-.246Zm0-.247v.246-.246Z"
                    fill="#0073A5"
                />
                <path
                    d="M27.508 35.533c0-.123.123-.369.123-.492a4.43 4.43 0 0 1-.492-1.106 2.58 2.58 0 0 0-.369-.861 4.302 4.302 0 0 1-.615-.984c0-.246-.123-.369-.123-.737v-.492h-.123a.246.246 0 0 0-.245-.246h-.37a.123.123 0 0 0-.122-.123h-.246c-.123.123-.246.123-.246.246a.246.246 0 0 0 0 .369c-.123.492.123.86.123 1.352.023.42.106.834.246 1.23l.614 1.352c.066.465.19.92.37 1.353h.245l.37.245h.49a.123.123 0 0 0 .124-.123.123.123 0 0 0 .123-.122h.123l.246-.37c0-.122-.246-.368-.246-.491Z"
                    fill="#006E49"
                />
                <path
                    d="M15.827 20.41c0-.123 0-.123-.123-.123 0-.123 0-.123-.123-.123h-1.352l-.984-.615-1.107-.369c-.368-.123-.614-.491-.86-.737h-1.23a.615.615 0 0 1-.492.245l-.368.246a.983.983 0 0 0 .491.492l.246.246 1.23.492c.324.277.7.486 1.106.615h1.353c.418.209.887.294 1.352.246h.246c.123 0 .246 0 .246.123h.123c.123 0 .123-.123.123-.246h.123c.123-.123 0-.123 0-.246v-.246ZM15.705 24.221l-.615-.369a1.353 1.353 0 0 0-.984.123h-.368l-1.353.246-1.23.492-1.475.246c-.123.123-.123.369-.246.369l-.369.369c0 .123.246.246.246.369a.492.492 0 0 0 .123.368c0 .123-.123.37 0 .492h.37c.245.123.368-.123.49-.123h.37l1.106-.369h.123l1.599-.86 1.106-.492 1.23-.246h.123v-.369c-.123 0-.123-.123-.246-.246Z"
                    fill="#BC2B30"
                />
                <path
                    d="M27.63 9.098c0-.123 0-.123-.123-.369-.123-.245.245-.491.245-.614s-.245-.246-.245-.37h-.984l-.369 1.107h-.123c0 .123-.123.246-.246.37-.214.344-.38.716-.491 1.106-.123.492-.123 1.106-.246 1.475-.02.373.022.747.123 1.107a.246.246 0 0 0 0 .369h-.123c0 .123.123.123.123.245h.245c0 .124 0 .124.123.124h.246a.369.369 0 0 0 .123-.246h.123a.368.368 0 0 1 .123-.246c.123-.37.123-.861.246-1.23.113-.39.278-.762.492-1.106l.369-1.107c.066-.425.19-.839.369-1.23v-.491 1.106Z"
                    fill="#0073A5"
                />
                <path
                    d="M38.574 25.82v-.246c-.123 0-.123-.246-.246-.37l-1.353-.49h-1.598a6.52 6.52 0 0 0-1.476-.37h-1.598l-.492.37h-.246c-.123.122-.123.122-.123.245h.246c0 .123.123.123.246.123l.615.246h.983l1.107.615 1.23.368 1.106.492h1.476c.123 0 .123 0 .123-.123s.123-.123.123-.246a.493.493 0 0 0-.123-.369"
                    fill="#823673"
                />
                <path
                    d="M24.925 38.115c0-.492-.246-.86-.246-1.353V35.41c-.123-.369-.123-.86-.246-1.23-.122-.368-.122-.737-.245-.983l-.37-.86a1.722 1.722 0 0 0-.245-.739.123.123 0 0 0-.123-.123.123.123 0 1 0-.246 0h-.123c-.123.123-.246.246-.246.37v.737c-.123.246-.123.492-.246.738-.11.315-.153.65-.123.983-.179.391-.303.805-.369 1.23-.196.427-.32.884-.369 1.352.038.338.121.668.246.984-.122.369-.122.738-.245.983v1.353l.368 1.107c.123.614.123 1.106.246 1.598-.019.212.024.426.123.615 0 .123 0 .123.123.245l.246.615a.49.49 0 0 1 .123.37.123.123 0 0 0 .123.122.122.122 0 0 1 .123.123h.123v-.246c0-.123.123-.369.123-.492s.246-.246.246-.368a.491.491 0 0 0 .123-.37l.369-.614c.123-.492.123-.984.245-1.476v-.245c0-.123.123-.246.123-.37V39.59a6.02 6.02 0 0 0 .123-1.475h.246Zm-1.844-7.008h.369c-.123 0-.123 0-.123-.123l-.246.123Z"
                    fill="#006E49"
                />
                <path
                    d="M46.319 22.377h-2.213c-.37-.123-.615-.369-.984-.369l-1.23-.492h-4.549l-.983.246h-1.23l-.983.246-.86.492c-.247.123-.493 0-.739 0l-.368.246a.123.123 0 0 0-.123.123c0 .123 0 .246.123.246h.245l.492.246.615.245h.738l.86.246 1.23.246a4.917 4.917 0 0 1 1.352 0h1.476c.442.109.898.15 1.352.123l1.353-.246h1.23l.983-.245.614-.37h.246c.246 0 .492.124.738-.122h.369c.123 0 .246 0 .246-.123"
                    fill="#823673"
                />
                <path
                    d="M21.852 30.615h-.123c-.246-.123-.246-.246-.37-.492h-.491c0 .123-.123.123-.123.246v.246l-.246.614v.246c-.123.37-.123.738-.246 1.107s-.369.615-.492.983l-.369 1.107v.86c-.122 0-.122.124-.122.247l.245.368v.37l.37.245h.737a.737.737 0 0 0 .123-.492c.18-.422.385-.832.615-1.23.123-.491.123-.983.246-1.352a5.41 5.41 0 0 0 .245-1.23c0-.368.123-.737.123-.983v-.246c.123-.123.123-.123.123-.245h-.123l-.122-.37Z"
                    fill="#006E49"
                />
                <path
                    d="m38.574 18.566-.246-.246h-.37c-.122-.123-.245 0-.368 0s-.369-.246-.492-.246h-.492a2.09 2.09 0 0 1-.983.737l-.984.37-1.23.368a.615.615 0 0 0-.614.369h-.123a.123.123 0 0 0-.123.123h-1.107v.246h.123a.123.123 0 0 0 .123.123c0 .123.123.123.246.123h.123a.123.123 0 0 1 .123.123h.123a.123.123 0 0 1 .123-.123h.369c-.123 0-.123 0-.123-.123a.124.124 0 0 0 .036.087.122.122 0 0 0 .087.036h1.352l1.476-.37 1.475-.49 1.23-.616.245-.368c.123 0 .123-.123.123-.246v-.37l-.122.492Z"
                    fill="#823673"
                />
            </g>
        </svg>
    );

    const mohegansun_full_logo = (
        <svg xmlns="http://www.w3.org/2000/svg" width="130" height="28" viewBox="0 0 130 28" fill="none">
            <g clip-path="url(#clip0_2648_51239)">
                <path
                    d="M17.637 14.3157L16.9021 12.699C16.9021 12.6255 16.7551 12.552 16.7551 12.4785H16.6816L16.3877 12.3315L15.8733 12.1846C15.7831 12.2163 15.7057 12.2764 15.6527 12.3559C15.5997 12.4355 15.5739 12.5301 15.5793 12.6255C15.5793 12.9195 15.7263 12.9195 15.7263 13.2134C15.7263 13.5074 15.6528 13.4339 15.6528 13.7278V15.3446C15.6528 15.6385 15.7263 15.4915 15.7263 15.7855H16.1672L16.6816 15.418L16.9021 15.2711L17.5635 14.4627C17.5901 14.4329 17.6103 14.3979 17.6229 14.36C17.6356 14.3221 17.6404 14.282 17.637 14.2422"
                    fill="#FFD851"
                />
                <path
                    d="M8.67139 10.127V10.4209H8.74487V10.5679H9.70022C9.70987 10.5679 9.71942 10.566 9.72834 10.5623C9.73725 10.5586 9.74536 10.5532 9.75218 10.5464C9.759 10.5395 9.76442 10.5314 9.76811 10.5225C9.7718 10.5136 9.7737 10.5041 9.7737 10.4944C9.84719 10.4944 9.84719 10.4209 9.84719 10.3474H9.92068V10.2005C9.92068 10.127 9.99417 10.127 9.99417 10.0535V9.9065H9.92068C9.92068 9.83301 9.92068 9.75953 9.84719 9.75953C9.84719 9.74004 9.83945 9.72134 9.82567 9.70756C9.81189 9.69378 9.79319 9.68604 9.7737 9.68604L9.62673 9.53906H8.89185L8.74487 9.68604C8.74487 9.70553 8.73713 9.72422 8.72335 9.738C8.70957 9.75178 8.69088 9.75953 8.67139 9.75953V10.127Z"
                    fill="white"
                />
                <path
                    d="M16.9756 10.127V10.2739V10.4209C16.9756 10.4306 16.9775 10.4401 16.9812 10.449C16.9849 10.458 16.9903 10.4661 16.9971 10.4729C17.0039 10.4797 17.012 10.4851 17.021 10.4888C17.0299 10.4925 17.0394 10.4944 17.0491 10.4944L17.1961 10.6414H18.0779V10.4944C18.0779 10.4209 18.1514 10.4209 18.1514 10.3474C18.1939 10.3097 18.2202 10.2571 18.2249 10.2005V10.0535C18.2202 9.99684 18.1939 9.94419 18.1514 9.9065C18.1514 9.83301 18.0779 9.83301 18.0779 9.75952H17.343C17.343 9.83301 17.2695 9.83301 17.1961 9.83301H16.9021C16.9021 9.9065 16.8286 9.9065 16.8286 9.97999V10.127"
                    fill="white"
                />
                <path
                    d="M8.67139 18.1372C8.67139 18.2107 8.67139 18.2842 8.74487 18.2842V18.4312C8.81836 18.5047 8.81836 18.5047 8.81836 18.5782H8.89185L9.03883 18.7251H9.70022C9.70022 18.6516 9.77371 18.6516 9.77371 18.5782H9.99417C9.99417 18.5047 9.99417 18.5047 10.0677 18.4312V18.1372H10.1411C10.0986 18.0995 10.0723 18.0469 10.0677 17.9902C9.99417 17.9168 9.99417 17.9168 9.99417 17.8433H9.92068L9.77371 17.6963H9.03883L8.89185 17.8433C8.89185 17.8628 8.88411 17.8814 8.87033 17.8952C8.85655 17.909 8.83785 17.9168 8.81836 17.9168C8.81836 17.9902 8.74487 17.9902 8.74487 18.0637V18.2107H8.67139"
                    fill="white"
                />
                <path
                    d="M16.9023 18.2107V18.3577C16.9023 18.4312 16.9758 18.4312 16.9758 18.5047C16.9805 18.5613 17.0068 18.6139 17.0493 18.6516C17.0688 18.6516 17.0875 18.6594 17.1013 18.6732C17.1151 18.6869 17.1228 18.7056 17.1228 18.7251H17.7842C17.8037 18.7251 17.8224 18.7174 17.8362 18.7036C17.8499 18.6898 17.8577 18.6711 17.8577 18.6516H18.2251V18.2107V18.0637H18.1516C18.1516 17.9902 18.1516 17.9902 18.0781 17.9168V17.7698H17.7842C17.7842 17.7503 17.7765 17.7316 17.7627 17.7178C17.7489 17.704 17.7302 17.6963 17.7107 17.6963H17.1963C17.1963 17.7698 17.1228 17.7698 17.1228 17.8433C17.1033 17.8433 17.0846 17.851 17.0708 17.8648C17.0571 17.8786 17.0493 17.8973 17.0493 17.9168C16.9758 17.9168 16.9758 17.9902 16.9758 18.0637C16.9333 18.1014 16.907 18.1541 16.9023 18.2107Z"
                    fill="white"
                />
                <path
                    d="M11.8313 12.0377C12.1253 11.9642 12.1253 12.0377 12.4192 12.1112H14.0359C14.2564 12.1112 14.2564 12.2581 14.5504 12.2581H15.2852V11.3763C15.2117 11.2293 15.2117 11.1558 15.1383 11.0823L14.7708 10.7884L14.5504 10.5679L14.0359 10.2005C13.6181 10.1836 13.1996 10.2082 12.7866 10.274H12.5662L12.0518 11.0088C12.0518 11.0823 11.9783 11.1558 11.9783 11.2293L11.7578 11.8172C11.7578 11.9642 11.8313 12.0377 11.9048 11.9642"
                    fill="#FFD851"
                />
                <path
                    d="M15.2117 16.3735C14.9178 16.3 14.9178 16.447 14.6238 16.447C14.3299 16.447 14.4034 16.3 14.1094 16.3H13.0071C12.7866 16.3 12.7866 16.447 12.4927 16.3735H11.9783C11.7578 16.3735 11.6843 16.3 11.6108 16.447V16.6675L11.9783 17.1819C11.9983 17.2919 12.0493 17.3938 12.1253 17.4759L12.7866 17.9903H13.3745L14.7708 17.3289L14.9913 17.1819L15.1383 16.9614V16.3735"
                    fill="#FFD851"
                />
                <path
                    d="M11.4641 15.2711V14.6832C11.4641 14.4627 11.3906 14.4627 11.3906 14.1687V13.6543C11.3906 13.3604 11.3171 13.3604 11.3171 13.0664C11.3171 12.7725 11.1701 12.846 11.2436 12.552C11.3171 12.2581 11.3171 12.3315 11.0966 12.2581H10.8027C10.7292 12.2581 10.5822 12.3315 10.5822 12.405L10.2148 12.7725C10.2148 12.846 10.0678 12.9194 10.0678 12.9929L9.70037 13.5074C9.68576 13.9252 9.71037 14.3434 9.77386 14.7566C9.77386 14.8301 9.77386 14.9771 9.84735 14.9771L10.5822 15.4915H10.8027L11.4641 15.712V15.2711Z"
                    fill="#FFD851"
                />
                <path
                    d="M14.2566 5.71765C14.3643 5.43331 14.4383 5.13734 14.4771 4.83579C14.4771 4.54184 14.3301 4.24789 14.3301 4.02742C14.3301 3.80696 14.4036 3.43952 14.4036 3.21905L14.1831 2.48417C14.1697 2.25728 14.1201 2.034 14.0362 1.82278C13.9627 1.67581 13.8157 1.52883 13.8157 1.38185C13.8157 1.23488 13.8157 1.01441 13.8157 0.940925L13.6687 0.793948H13.5952C13.5952 0.774458 13.5875 0.755767 13.5737 0.741985C13.5599 0.728203 13.5412 0.720461 13.5217 0.720461C13.5023 0.720461 13.4836 0.728203 13.4698 0.741985C13.456 0.755767 13.4483 0.774458 13.4483 0.793948H13.3748C13.3781 0.833761 13.3734 0.873846 13.3607 0.911752C13.3481 0.949657 13.3279 0.984589 13.3013 1.01441V1.30836C13.2113 1.41018 13.1594 1.54002 13.1543 1.67581C13.1543 1.68546 13.1524 1.69501 13.1487 1.70393C13.145 1.71284 13.1396 1.72095 13.1328 1.72777C13.126 1.73459 13.1179 1.74001 13.1089 1.7437C13.1 1.74739 13.0905 1.74929 13.0808 1.74929V1.89627C13.0073 2.11673 13.0073 2.3372 12.9338 2.55766C12.8731 2.79763 12.8483 3.0453 12.8604 3.29254L12.6399 4.10091C12.6399 4.32137 12.5664 4.61533 12.5664 4.90928C12.5664 5.20323 12.7134 5.49718 12.7134 5.79114C12.7013 6.06267 12.726 6.3346 12.7869 6.59951C12.8604 6.81997 12.7869 7.11392 12.8604 7.33439C12.9338 7.55485 13.0808 7.70183 13.1543 7.92229C13.1425 8.09702 13.1675 8.27228 13.2278 8.43671C13.2278 8.58368 13.3013 8.73066 13.3013 8.87763L13.5217 9.02461C13.5217 8.95112 13.6687 8.95112 13.7422 8.80415C13.7714 8.67464 13.821 8.5506 13.8892 8.43671C13.8774 8.26198 13.9024 8.08672 13.9627 7.92229C13.9627 7.70183 14.1831 7.55485 14.2566 7.33439C14.2705 7.08369 14.32 6.83625 14.4036 6.59951C14.4036 6.30555 14.2566 6.0116 14.2566 5.79114V5.71765ZM13.5952 0.720461V0.646973V0.720461Z"
                    fill="#0073A5"
                />
                <path
                    d="M8.4511 14.0217H8.30413L8.01018 13.8748H7.64274C7.49576 13.8748 7.42227 13.7278 7.20181 13.6543L6.61391 13.5073L5.87903 13.3604L5.07066 13.0664L4.62973 13.2134H4.33578C4.11327 13.2628 3.88063 13.237 3.67439 13.1399H3.38044C3.08649 13.1399 2.86603 13.3604 2.57207 13.3604H1.8372L1.39627 13.5073H0.955342L0.146975 13.7278H0V13.8748H0.220463L0.440927 14.0217H0.881853L1.24929 14.2422L1.8372 14.3892L2.57207 14.6097H5.07066C5.34837 14.5707 5.62001 14.4967 5.87903 14.3892L6.54042 14.2422C6.76088 14.1687 7.05483 14.3157 7.2753 14.2422H7.34879L7.8632 14.0217H8.4511Z"
                    fill="#BC2B30"
                />
                <path
                    d="M14.6243 14.1687C14.6243 14.0952 14.5508 14.0218 14.5508 13.9483C14.5361 13.792 14.4858 13.6412 14.4038 13.5073H13.8894L13.522 13.0664H13.0076C12.9341 13.0664 12.8606 13.0664 12.8606 13.1399L12.7136 13.3604C12.6746 13.3604 12.6373 13.3758 12.6097 13.4034C12.5821 13.431 12.5667 13.4684 12.5667 13.5073L12.3462 13.8013C12.3462 13.8748 12.4197 14.3892 12.4197 14.4627C12.4197 14.5362 12.4197 14.6097 12.4932 14.6097H12.5667C12.6273 14.6917 12.7044 14.7602 12.7929 14.8108C12.8815 14.8614 12.9796 14.893 13.0811 14.9036H13.3015C13.3153 14.9192 13.3323 14.9318 13.3513 14.9404C13.3703 14.9489 13.3909 14.9534 13.4118 14.9534C13.4326 14.9534 13.4532 14.9489 13.4722 14.9404C13.4912 14.9318 13.5082 14.9192 13.522 14.9036H13.7425L14.0364 14.7566L14.1834 14.6097L14.4038 14.3892H14.4773C14.5199 14.3515 14.5462 14.2988 14.5508 14.2422C14.6243 14.1687 14.6243 14.1687 14.6243 14.0952"
                    fill="#BC2B30"
                />
                <path
                    d="M12.6399 9.09798C12.6772 8.82981 12.6772 8.55779 12.6399 8.28962C12.6263 8.01491 12.5769 7.74316 12.493 7.48125C12.4641 7.20155 12.3897 6.92847 12.2725 6.67288C12.1873 6.42301 12.0892 6.17772 11.9785 5.938L11.8316 5.71753C11.7581 5.64405 11.7581 5.49707 11.6846 5.49707C11.6111 5.49707 11.5376 5.49707 11.4641 5.57056C11.3906 5.64405 11.3906 5.71753 11.3171 5.79102H11.0967C11.0967 5.86451 10.9497 5.938 10.9497 6.01149V6.30544C10.9497 6.37893 11.0967 6.45241 11.0967 6.5259C11.0967 6.59939 11.3171 6.89334 11.3906 7.11381C11.4641 7.33427 11.3906 7.70171 11.4641 7.92218C11.5376 8.14264 11.7581 8.21613 11.8316 8.43659C11.8708 8.61071 11.9198 8.78246 11.9785 8.95101V9.09798C11.9675 9.20157 11.9936 9.30571 12.052 9.39194H12.4195C12.493 9.39194 12.493 9.31845 12.5664 9.24496H12.6399V9.09798ZM12.6399 8.95101V9.09798V8.95101Z"
                    fill="#0073A5"
                />
                <path
                    d="M15.947 21.8851C15.947 21.8116 16.0205 21.6646 16.0205 21.5911C15.8927 21.3852 15.7938 21.1626 15.7265 20.9297C15.685 20.7463 15.6103 20.5719 15.5061 20.4153C15.3576 20.2368 15.234 20.0391 15.1386 19.8274C15.1386 19.6804 15.0652 19.6069 15.0652 19.3865V19.0925H14.9917C14.9917 19.0536 14.9762 19.0162 14.9486 18.9886C14.9211 18.961 14.8837 18.9456 14.8447 18.9456H14.6242C14.6242 18.9261 14.6165 18.9074 14.6027 18.8936C14.5889 18.8798 14.5702 18.8721 14.5507 18.8721H14.4038C14.3303 18.9456 14.2568 18.9456 14.2568 19.019C14.2412 19.0328 14.2286 19.0498 14.22 19.0688C14.2115 19.0878 14.207 19.1084 14.207 19.1293C14.207 19.1501 14.2115 19.1707 14.22 19.1897C14.2286 19.2088 14.2412 19.2257 14.2568 19.2395C14.1833 19.5335 14.3303 19.7539 14.3303 20.0479C14.3441 20.2986 14.3936 20.546 14.4773 20.7828L14.8447 21.5911C14.8836 21.8688 14.9577 22.1405 15.0652 22.3995H15.2121L15.4326 22.5465H15.7265C15.746 22.5465 15.7647 22.5387 15.7785 22.5249C15.7923 22.5112 15.8 22.4925 15.8 22.473C15.8195 22.473 15.8382 22.4652 15.852 22.4515C15.8658 22.4377 15.8735 22.419 15.8735 22.3995H15.947L16.094 22.179C16.094 22.1055 15.947 21.9586 15.947 21.8851Z"
                    fill="#006E49"
                />
                <path
                    d="M8.96542 12.846C8.96542 12.7725 8.96542 12.7725 8.89193 12.7725C8.89193 12.699 8.89193 12.699 8.81844 12.699H8.01008L7.42217 12.3316L6.76078 12.1111C6.54032 12.0376 6.39334 11.8171 6.24637 11.6702H5.51149C5.47726 11.7158 5.43288 11.7528 5.38186 11.7784C5.33084 11.8039 5.27458 11.8171 5.21753 11.8171L4.99707 11.9641C5.05599 12.0946 5.16051 12.1992 5.29102 12.2581L5.438 12.405L6.17288 12.699C6.36664 12.8643 6.59152 12.9893 6.83427 13.0664H7.64264C7.89264 13.1913 8.17305 13.2423 8.451 13.2134H8.59798C8.67147 13.2134 8.74496 13.2134 8.74496 13.2869H8.81844C8.89193 13.2869 8.89193 13.2134 8.89193 13.1399H8.96542C9.03891 13.0664 8.96542 13.0664 8.96542 12.9929V12.846Z"
                    fill="#BC2B30"
                />
                <path
                    d="M8.89217 15.1242L8.52473 14.9037C8.32653 14.8529 8.11644 14.8792 7.93683 14.9772H7.71637L6.908 15.1242L6.17312 15.4181L5.29127 15.5651C5.21778 15.6386 5.21778 15.7856 5.14429 15.7856L4.92383 16.006C4.92383 16.0795 5.0708 16.153 5.0708 16.2265C5.06743 16.2663 5.0722 16.3064 5.08484 16.3443C5.09747 16.3822 5.1177 16.4171 5.14429 16.447C5.14429 16.5205 5.0708 16.6674 5.14429 16.7409H5.36476C5.51173 16.8144 5.58522 16.6674 5.65871 16.6674H5.87917L6.54056 16.447H6.61405L7.56939 15.9326L8.23078 15.6386L8.96566 15.4916H9.03915V15.2712C8.96566 15.2712 8.96566 15.1977 8.89217 15.1242Z"
                    fill="#BC2B30"
                />
                <path
                    d="M16.0201 6.08498C16.0201 6.01149 16.0201 6.01149 15.9466 5.86452C15.8731 5.71754 16.0936 5.57056 16.0936 5.49708C16.0936 5.42359 15.9466 5.3501 15.9466 5.27661H15.3587L15.1383 5.938H15.0648C15.0648 6.01149 14.9913 6.08498 14.9178 6.15847C14.79 6.36443 14.6911 6.58697 14.6239 6.81986C14.5504 7.11381 14.5504 7.48125 14.4769 7.70172C14.4653 7.92471 14.4901 8.14809 14.5504 8.36311C14.5347 8.3769 14.5222 8.39387 14.5136 8.41287C14.505 8.43188 14.5006 8.45249 14.5006 8.47334C14.5006 8.49419 14.505 8.51481 14.5136 8.53381C14.5222 8.55282 14.5347 8.56978 14.5504 8.58357H14.4769C14.4769 8.65706 14.5504 8.65706 14.5504 8.73055H14.6973C14.6973 8.80404 14.6973 8.80404 14.7708 8.80404H14.9178C14.9603 8.76635 14.9867 8.71369 14.9913 8.65706H15.0648C15.0694 8.60043 15.0957 8.54778 15.1383 8.51009C15.2118 8.28962 15.2118 7.99567 15.2852 7.7752C15.3525 7.54232 15.4514 7.31977 15.5792 7.11381L15.7997 6.45242C15.839 6.19848 15.9132 5.95119 16.0201 5.71754V5.42359V6.08498Z"
                    fill="#0073A5"
                />
                <path
                    d="M22.5606 16.0794V15.9324C22.4871 15.9324 22.4871 15.7854 22.4137 15.7119L21.6053 15.418H20.65C20.3656 15.3103 20.0696 15.2363 19.7681 15.1975H18.8128L18.5188 15.418H18.3718C18.2983 15.4915 18.2983 15.4915 18.2983 15.565H18.4453C18.4453 15.6384 18.5188 15.6384 18.5923 15.6384L18.9597 15.7854H19.5476L20.209 16.1529L20.9439 16.3733L21.6053 16.6673H22.4871C22.5606 16.6673 22.5606 16.6673 22.5606 16.5938C22.5606 16.5203 22.6341 16.5203 22.6341 16.4468C22.6375 16.407 22.6327 16.3669 22.6201 16.329C22.6075 16.2911 22.5872 16.2562 22.5606 16.2263"
                    fill="#823673"
                />
                <path
                    d="M14.4039 23.4283C14.4039 23.1344 14.2569 22.9139 14.2569 22.62V21.8116C14.1834 21.5911 14.1834 21.2972 14.1099 21.0767C14.0364 20.8562 14.0364 20.6358 13.9629 20.4888L13.7425 19.9744C13.7277 19.8181 13.6775 19.6673 13.5955 19.5335C13.5955 19.514 13.5877 19.4953 13.574 19.4815C13.5602 19.4677 13.5415 19.46 13.522 19.46C13.522 19.4405 13.5143 19.4218 13.5005 19.408C13.4867 19.3942 13.468 19.3865 13.4485 19.3865C13.429 19.3865 13.4103 19.3942 13.3965 19.408C13.3828 19.4218 13.375 19.4405 13.375 19.46H13.3015C13.228 19.5335 13.1546 19.6069 13.1546 19.6804V20.1214C13.0811 20.2683 13.0811 20.4153 13.0076 20.5623C12.9413 20.7507 12.9163 20.9512 12.9341 21.1502C12.8271 21.3838 12.753 21.6311 12.7136 21.8851C12.5965 22.1407 12.522 22.4137 12.4932 22.6934C12.5158 22.8951 12.5652 23.0928 12.6401 23.2813C12.5667 23.5018 12.5667 23.7223 12.4932 23.8692V24.6776L12.7136 25.339C12.7871 25.7064 12.7871 26.0004 12.8606 26.2944C12.8492 26.4214 12.8747 26.549 12.9341 26.6618C12.9341 26.7353 12.9341 26.7353 13.0076 26.8088L13.1546 27.1762C13.1811 27.206 13.2014 27.241 13.214 27.2789C13.2266 27.3168 13.2314 27.3569 13.228 27.3967C13.228 27.4162 13.2358 27.4349 13.2496 27.4486C13.2634 27.4624 13.282 27.4702 13.3015 27.4702C13.3112 27.4702 13.3207 27.4721 13.3297 27.4758C13.3386 27.4794 13.3467 27.4849 13.3535 27.4917C13.3603 27.4985 13.3657 27.5066 13.3694 27.5155C13.3731 27.5244 13.375 27.534 13.375 27.5436H13.4485V27.3967C13.4485 27.3232 13.522 27.1762 13.522 27.1027C13.522 27.0292 13.669 26.9557 13.669 26.8823C13.6956 26.8524 13.7158 26.8175 13.7284 26.7796C13.7411 26.7417 13.7458 26.7016 13.7425 26.6618L13.9629 26.2944C14.0364 26.0004 14.0364 25.7064 14.1099 25.4125V25.2655C14.1099 25.192 14.1834 25.1185 14.1834 25.0451V24.3102C14.2441 24.0204 14.2687 23.7242 14.2569 23.4283H14.4039ZM13.3015 19.2395H13.522C13.4485 19.2395 13.4485 19.2395 13.4485 19.166L13.3015 19.2395Z"
                    fill="#006E49"
                />
                <path
                    d="M27.1906 14.0217H25.8678C25.6474 13.9483 25.5004 13.8013 25.2799 13.8013L24.5451 13.5073H21.826L21.2381 13.6543H20.5032L19.9153 13.8013L19.4009 14.0952C19.2539 14.1687 19.1069 14.0952 18.96 14.0952L18.7395 14.2422C18.72 14.2422 18.7013 14.2499 18.6875 14.2637C18.6738 14.2775 18.666 14.2962 18.666 14.3157C18.666 14.3892 18.666 14.4627 18.7395 14.4627H18.8865L19.1804 14.6096L19.5479 14.7566H19.9888L20.5032 14.9036L21.2381 15.0506C21.5063 15.0133 21.7783 15.0133 22.0465 15.0506H22.9283C23.1926 15.1154 23.4651 15.1401 23.7367 15.1241L24.5451 14.9771H25.2799L25.8678 14.8301L26.2353 14.6096H26.3822C26.5292 14.6096 26.6762 14.6831 26.8232 14.5362H27.0436C27.1171 14.5362 27.1906 14.5362 27.1906 14.4627"
                    fill="#823673"
                />
                <path
                    d="M12.5662 18.9456H12.4927C12.3457 18.8721 12.3457 18.7986 12.2722 18.6516H11.9783C11.9783 18.7251 11.9048 18.7251 11.9048 18.7986V18.9456L11.7578 19.313V19.46C11.6843 19.6804 11.6843 19.9009 11.6109 20.1214C11.5374 20.3418 11.3904 20.4888 11.3169 20.7093L11.0964 21.3707V21.8851C11.023 21.8851 11.0229 21.9586 11.0229 22.0321L11.1699 22.2525V22.473L11.3904 22.62H11.8313C11.8898 22.5337 11.9158 22.4296 11.9048 22.326C12.0124 22.0739 12.1351 21.8285 12.2722 21.5911C12.3457 21.2972 12.3457 21.0032 12.4192 20.7828C12.4965 20.5443 12.5459 20.2977 12.5662 20.0479C12.5662 19.8274 12.6397 19.607 12.6397 19.46V19.313C12.7132 19.2395 12.7132 19.2395 12.7132 19.166H12.6397L12.5662 18.9456Z"
                    fill="#006E49"
                />
                <path
                    d="M22.5606 11.7437L22.4137 11.5967H22.1932C22.1197 11.5232 22.0462 11.5967 21.9727 11.5967C21.8992 11.5967 21.7523 11.4497 21.6788 11.4497H21.3848C21.2375 11.6523 21.0327 11.8059 20.7969 11.8906L20.209 12.1111L19.4741 12.3316C19.3975 12.3253 19.3209 12.3431 19.255 12.3827C19.189 12.4222 19.1372 12.4815 19.1067 12.552H19.0332C19.0137 12.552 18.995 12.5598 18.9813 12.5736C18.9675 12.5873 18.9597 12.606 18.9597 12.6255H18.2983V12.7725H18.3718C18.3718 12.792 18.3796 12.8107 18.3934 12.8245C18.4071 12.8382 18.4258 12.846 18.4453 12.846C18.4453 12.9195 18.5188 12.9195 18.5923 12.9195H18.6658C18.6853 12.9195 18.704 12.9272 18.7177 12.941C18.7315 12.9548 18.7393 12.9735 18.7393 12.993H18.8128C18.8128 12.9735 18.8205 12.9548 18.8343 12.941C18.8481 12.9272 18.8668 12.9195 18.8862 12.9195H19.1067C19.0332 12.9195 19.0332 12.9195 19.0332 12.846C19.0332 12.8556 19.0351 12.8652 19.0388 12.8741C19.0425 12.883 19.0479 12.8911 19.0547 12.8979C19.0616 12.9048 19.0697 12.9102 19.0786 12.9139C19.0875 12.9176 19.0971 12.9195 19.1067 12.9195H19.9151L20.7969 12.699L21.6788 12.4051L22.4137 12.0376L22.5606 11.8171C22.6341 11.8171 22.6341 11.7437 22.6341 11.6702V11.4497L22.5606 11.7437Z"
                    fill="#823673"
                />
                <path
                    d="M38.287 2.70476L38.0666 2.4843C37.9992 2.44508 37.9246 2.41966 37.8473 2.40953C37.77 2.39939 37.6915 2.40474 37.6162 2.42526C37.541 2.44577 37.4706 2.48105 37.4091 2.52904C37.3477 2.57702 37.2964 2.63676 37.2582 2.70476L34.0982 7.04056L31.0117 2.63127C30.8998 2.51226 30.7477 2.43889 30.5849 2.42532C30.4221 2.41175 30.26 2.45894 30.1299 2.55779H29.9829C29.9147 2.67168 29.8651 2.79571 29.8359 2.92523V11.7438C29.8541 11.9062 29.9317 12.0561 30.0538 12.1647C30.176 12.2732 30.3339 12.3327 30.4973 12.3317C30.6478 12.3157 30.7882 12.2487 30.8952 12.1417C31.0022 12.0347 31.0693 11.8943 31.0852 11.7438V4.9094L33.5838 8.36334C33.6414 8.46532 33.7281 8.54778 33.8328 8.60015C33.9376 8.65253 34.0556 8.67242 34.1717 8.6573C34.2631 8.64623 34.3506 8.61387 34.4272 8.56282C34.5038 8.51176 34.5673 8.44344 34.6126 8.36334L37.1847 4.83591V11.8173C37.1847 11.9732 37.2467 12.1227 37.3569 12.233C37.4672 12.3432 37.6167 12.4052 37.7726 12.4052C37.9231 12.3892 38.0635 12.3222 38.1705 12.2152C38.2775 12.1082 38.3446 11.9677 38.3605 11.8173V2.99871C38.3621 2.89599 38.3368 2.79464 38.287 2.70476Z"
                    fill="white"
                />
                <path
                    d="M45.1213 2.26367C44.4684 2.27313 43.8238 2.41237 43.2252 2.67329C42.6266 2.93421 42.0859 3.3116 41.6345 3.78348C41.1832 4.25537 40.8302 4.81233 40.5961 5.42194C40.362 6.03155 40.2516 6.68164 40.2711 7.33435C40.1867 8.01639 40.2482 8.70858 40.4515 9.36506C40.6548 10.0215 40.9953 10.6273 41.4504 11.1423C41.9055 11.6572 42.4649 12.0696 43.0914 12.352C43.7179 12.6344 44.3973 12.7804 45.0846 12.7804C45.7718 12.7804 46.4512 12.6344 47.0777 12.352C47.7043 12.0696 48.2636 11.6572 48.7187 11.1423C49.1739 10.6273 49.5144 10.0215 49.7177 9.36506C49.921 8.70858 49.9825 8.01639 49.898 7.33435C49.9276 6.68524 49.8265 6.03681 49.6006 5.42754C49.3748 4.81828 49.0288 4.26061 48.5833 3.78764C48.1377 3.31467 47.6017 2.93605 47.007 2.67426C46.4123 2.41247 45.771 2.27284 45.1213 2.26367ZM45.1213 11.1557C44.6291 11.1462 44.1436 11.0397 43.6925 10.8425C43.2415 10.6453 42.8337 10.3611 42.4925 10.0062C42.1513 9.65137 41.8833 9.23275 41.7039 8.7743C41.5245 8.31585 41.4372 7.82655 41.4469 7.33435C41.4273 6.83607 41.5073 6.33888 41.6822 5.8719C41.8571 5.40493 42.1235 4.97755 42.4657 4.61483C42.8079 4.2521 43.219 3.96131 43.675 3.75949C44.131 3.55766 44.6227 3.44887 45.1213 3.43948C47.1055 3.43948 48.6487 5.20319 48.7222 7.33435C48.7422 8.31604 48.3762 9.26638 47.7028 9.981C47.0294 10.6956 46.1025 11.1174 45.1213 11.1557Z"
                    fill="white"
                />
                <path
                    d="M57.7616 2.41075C57.6057 2.41075 57.4561 2.47269 57.3459 2.58295C57.2356 2.6932 57.1737 2.84273 57.1737 2.99866V6.82004H52.8379V2.99866C52.8505 2.91859 52.8439 2.83668 52.8188 2.75962C52.7937 2.68256 52.7508 2.61252 52.6935 2.55521C52.6361 2.4979 52.5661 2.45494 52.489 2.42984C52.412 2.40474 52.3301 2.3982 52.25 2.41075C52.0941 2.41075 51.9446 2.47269 51.8343 2.58295C51.724 2.6932 51.6621 2.84273 51.6621 2.99866V11.8172C51.6781 11.9677 51.7451 12.1081 51.8521 12.2151C51.9591 12.3221 52.0996 12.3892 52.25 12.4051C52.4059 12.4051 52.5555 12.3432 52.6657 12.2329C52.776 12.1227 52.8379 11.9731 52.8379 11.8172V7.99585H57.1737V11.8172C57.1897 11.9677 57.2567 12.1081 57.3637 12.2151C57.4707 12.3221 57.6111 12.3892 57.7616 12.4051C57.925 12.4061 58.083 12.3466 58.2051 12.2381C58.3272 12.1295 58.4048 11.9796 58.423 11.8172V2.99866C58.4237 2.91486 58.4064 2.83189 58.3724 2.75531C58.3383 2.67873 58.2883 2.61031 58.2257 2.55464C58.1631 2.49897 58.0893 2.45733 58.0092 2.43251C57.9292 2.40769 57.8447 2.40028 57.7616 2.41075Z"
                    fill="white"
                />
                <path
                    d="M66.7269 11.1558H62.4646V7.99585H66.139C66.2949 7.99585 66.4445 7.93391 66.5547 7.82365C66.665 7.7134 66.7269 7.56386 66.7269 7.40794C66.7395 7.32787 66.7329 7.24597 66.7078 7.16891C66.6827 7.09184 66.6398 7.0218 66.5824 6.96449C66.5251 6.90719 66.4551 6.86423 66.378 6.83912C66.301 6.81402 66.2191 6.80748 66.139 6.82004H62.4646V3.58656H66.7269C66.8828 3.58656 67.0323 3.52462 67.1426 3.41437C67.2529 3.30411 67.3148 3.15458 67.3148 2.99866C67.3273 2.91859 67.3208 2.83668 67.2957 2.75962C67.2706 2.68256 67.2276 2.61252 67.1703 2.55521C67.113 2.4979 67.043 2.45494 66.9659 2.42984C66.8889 2.40474 66.807 2.3982 66.7269 2.41075H61.8767C61.7936 2.40028 61.7092 2.40769 61.6291 2.43251C61.5491 2.45733 61.4753 2.49897 61.4127 2.55464C61.35 2.61031 61.3 2.67873 61.266 2.75531C61.232 2.83189 61.2147 2.91486 61.2154 2.99866V11.8172C61.2313 11.9677 61.2984 12.1081 61.4054 12.2151C61.5124 12.3221 61.6528 12.3892 61.8032 12.4051H66.7269C66.8828 12.4051 67.0323 12.3432 67.1426 12.2329C67.2529 12.1227 67.3148 11.9731 67.3148 11.8172C67.3253 11.7341 67.3179 11.6497 67.293 11.5696C67.2682 11.4896 67.2266 11.4158 67.1709 11.3531C67.1152 11.2905 67.0468 11.2405 66.9702 11.2065C66.8937 11.1724 66.8107 11.1552 66.7269 11.1558Z"
                    fill="white"
                />
                <path
                    d="M76.9416 6.89349H74.0021C73.8267 6.89349 73.6584 6.96317 73.5344 7.0872C73.4104 7.21124 73.3407 7.37947 73.3407 7.55488C73.3588 7.71726 73.4365 7.86718 73.5586 7.97573C73.6807 8.08429 73.8387 8.14379 74.0021 8.14278H76.2802V10.4944C75.6925 10.8877 75.0104 11.1168 74.3045 11.158C73.5985 11.1992 72.8944 11.0511 72.2649 10.7288C71.6354 10.4066 71.1035 9.92202 70.7241 9.32523C70.3448 8.72845 70.1318 8.04114 70.1072 7.33441C70.1265 6.29473 70.553 5.30414 71.2952 4.57575C72.0373 3.84736 73.0357 3.43937 74.0756 3.43955C74.9849 3.43855 75.8669 3.74987 76.5742 4.3214C76.6925 4.41542 76.8425 4.46024 76.9931 4.44655C77.1436 4.43287 77.2831 4.36173 77.3825 4.24791C77.4645 4.11407 77.5148 3.96325 77.5295 3.80699C77.5409 3.71989 77.5259 3.63139 77.4867 3.55283C77.4474 3.47427 77.3855 3.40924 77.3091 3.36606C76.3855 2.64618 75.2466 2.25792 74.0756 2.26374C72.7307 2.26374 71.441 2.79797 70.4901 3.7489C69.5391 4.69984 69.0049 5.98959 69.0049 7.33441C69.0049 8.67924 69.5391 9.96899 70.4901 10.9199C71.441 11.8709 72.7307 12.4051 74.0756 12.4051C75.2466 12.4109 76.3855 12.0226 77.3091 11.3028C77.4366 11.1897 77.5156 11.0317 77.5295 10.8618V7.55488C77.5305 7.39149 77.471 7.2335 77.3625 7.11138C77.2539 6.98925 77.104 6.91164 76.9416 6.89349Z"
                    fill="white"
                />
                <path
                    d="M87.4508 11.5967L84.2173 2.77814C84.1629 2.66849 84.0791 2.57609 83.9753 2.51122C83.8715 2.44635 83.7518 2.41155 83.6294 2.4107C83.5051 2.40174 83.3812 2.4325 83.2755 2.49854C83.1698 2.56459 83.0879 2.6625 83.0415 2.77814L79.7345 11.5967C79.6995 11.6733 79.6813 11.7564 79.6812 11.8406C79.681 11.9248 79.699 12.0081 79.7338 12.0847C79.7687 12.1613 79.8196 12.2296 79.8831 12.2849C79.9466 12.3402 80.0212 12.3811 80.1019 12.4051H80.3224C80.4342 12.3992 80.5421 12.3615 80.6332 12.2964C80.7243 12.2313 80.795 12.1415 80.8368 12.0376L81.6452 9.97997H85.5401L86.3484 12.0376C86.3948 12.1533 86.4768 12.2512 86.5825 12.3172C86.6881 12.3833 86.812 12.414 86.9363 12.4051H87.1568C87.2924 12.3181 87.3957 12.189 87.4508 12.0376C87.4895 11.9706 87.5098 11.8946 87.5098 11.8172C87.5098 11.7398 87.4895 11.6637 87.4508 11.5967ZM85.0991 8.73068H82.0861L83.6294 4.68883L85.0991 8.73068Z"
                    fill="white"
                />
                <path
                    d="M96.0484 2.48419C95.885 2.48318 95.7271 2.54269 95.6049 2.65124C95.4828 2.75979 95.4052 2.90971 95.387 3.0721V9.90649L90.1694 2.70465C90.1256 2.63185 90.0665 2.56941 89.9962 2.52164C89.9259 2.47386 89.8461 2.44189 89.7623 2.42792C89.6785 2.41395 89.5927 2.41832 89.5107 2.44072C89.4287 2.46312 89.3526 2.50302 89.2875 2.55768C89.2134 2.63602 89.1557 2.72839 89.1179 2.82935C89.08 2.93032 89.0627 3.03784 89.0671 3.14558V11.8172C89.0852 11.9796 89.1628 12.1295 89.2849 12.238C89.4071 12.3466 89.5651 12.4061 89.7284 12.4051C89.8789 12.3891 90.0193 12.322 90.1263 12.2151C90.2333 12.1081 90.3004 11.9676 90.3164 11.8172V4.9093L95.4605 12.1111H95.534C95.5984 12.1832 95.6779 12.2404 95.7668 12.2785C95.8556 12.3165 95.9518 12.3347 96.0484 12.3316C96.1989 12.3156 96.3393 12.2486 96.4463 12.1416C96.5533 12.0346 96.6204 11.8941 96.6363 11.7437V3.0721C96.6204 2.92164 96.5533 2.7812 96.4463 2.67421C96.3393 2.56723 96.1989 2.50016 96.0484 2.48419Z"
                    fill="white"
                />
                <path
                    d="M108.541 7.48131C107.77 6.97996 106.886 6.67704 105.969 6.59946L104.059 5.93806C103.893 5.83567 103.754 5.69596 103.652 5.53009C103.549 5.36421 103.488 5.17677 103.471 4.98272C103.485 4.75944 103.552 4.54275 103.667 4.35092C103.782 4.15908 103.942 3.99771 104.132 3.8804C104.66 3.54579 105.271 3.36743 105.896 3.36598C106.781 3.4372 107.625 3.76971 108.321 4.32133C108.38 4.37534 108.45 4.41502 108.526 4.43723C108.603 4.45945 108.683 4.46359 108.762 4.44934C108.84 4.43509 108.914 4.40284 108.978 4.35512C109.042 4.3074 109.094 4.24552 109.129 4.17435C109.2 4.04278 109.223 3.89132 109.197 3.74458C109.17 3.59783 109.094 3.46442 108.982 3.36598C108.079 2.7134 107.008 2.3311 105.896 2.26366C105.027 2.22567 104.172 2.48489 103.471 2.99854C103.132 3.19526 102.85 3.4759 102.652 3.81339C102.453 4.15088 102.345 4.53383 102.338 4.92529C102.33 5.31675 102.424 5.70351 102.61 6.04825C102.795 6.39298 103.066 6.68407 103.397 6.89341C104.141 7.32716 104.967 7.60238 105.822 7.70178C106.55 7.80697 107.25 8.05684 107.88 8.43666C108.075 8.54901 108.238 8.70919 108.354 8.90207C108.469 9.09495 108.534 9.31416 108.541 9.53898C108.532 9.78199 108.459 10.0183 108.33 10.2246C108.201 10.4308 108.021 10.5998 107.807 10.7148C107.243 11.0913 106.572 11.2721 105.896 11.2292C104.832 11.1464 103.813 10.7642 102.956 10.1269C102.903 10.0697 102.837 10.0255 102.764 9.99768C102.691 9.96982 102.612 9.95902 102.534 9.96611C102.456 9.9732 102.381 9.99798 102.314 10.0386C102.247 10.0792 102.19 10.1345 102.148 10.2004C102.069 10.3241 102.033 10.4708 102.046 10.6172C102.059 10.7635 102.121 10.9013 102.222 11.0087C103.267 11.8529 104.554 12.342 105.896 12.405C106.839 12.4069 107.761 12.1252 108.541 11.5966C108.884 11.3687 109.169 11.064 109.373 10.7068C109.577 10.3496 109.695 9.94976 109.717 9.53898C109.707 9.12547 109.594 8.72101 109.389 8.36187C109.184 8.00274 108.892 7.70017 108.541 7.48131Z"
                    fill="white"
                />
                <path
                    d="M119.197 2.41075C119.117 2.3982 119.036 2.40474 118.958 2.42984C118.881 2.45494 118.811 2.4979 118.754 2.55521C118.697 2.61252 118.654 2.68256 118.629 2.75962C118.604 2.83668 118.597 2.91859 118.61 2.99866V8.2898C118.499 8.98036 118.147 9.60901 117.615 10.0628C117.082 10.5167 116.406 10.766 115.707 10.766C115.007 10.766 114.331 10.5167 113.799 10.0628C113.267 9.60901 112.914 8.98036 112.804 8.2898V2.99866C112.804 2.92145 112.789 2.845 112.759 2.77368C112.73 2.70235 112.686 2.63754 112.632 2.58295C112.577 2.52835 112.512 2.48505 112.441 2.4555C112.37 2.42596 112.293 2.41075 112.216 2.41075C112.133 2.40028 112.049 2.40769 111.969 2.43251C111.888 2.45733 111.815 2.49897 111.752 2.55464C111.689 2.61031 111.639 2.67873 111.605 2.75531C111.571 2.83189 111.554 2.91486 111.555 2.99866V8.2898C111.555 9.38125 111.988 10.428 112.76 11.1998C113.532 11.9716 114.579 12.4051 115.67 12.4051C116.762 12.4051 117.808 11.9716 118.58 11.1998C119.352 10.428 119.785 9.38125 119.785 8.2898V2.99866C119.785 2.84273 119.723 2.6932 119.613 2.58295C119.503 2.47269 119.353 2.41075 119.197 2.41075Z"
                    fill="white"
                />
                <path
                    d="M129.339 2.48419C129.188 2.50016 129.048 2.56723 128.941 2.67421C128.834 2.7812 128.767 2.92164 128.751 3.07209V9.90648L123.533 2.70465C123.489 2.63185 123.43 2.56941 123.36 2.52164C123.29 2.47386 123.21 2.44189 123.126 2.42792C123.042 2.41395 122.956 2.41832 122.874 2.44072C122.792 2.46312 122.716 2.50302 122.651 2.55768C122.555 2.61759 122.481 2.70775 122.441 2.81409C122.401 2.92044 122.398 3.037 122.431 3.14558V11.8172C122.447 11.9676 122.514 12.1081 122.621 12.2151C122.728 12.322 122.868 12.3891 123.019 12.4051C123.175 12.4051 123.324 12.3431 123.434 12.2329C123.545 12.1226 123.607 11.9731 123.607 11.8172V4.9093L128.824 12.1111H128.898C128.947 12.1818 129.013 12.2391 129.09 12.2776C129.167 12.3161 129.253 12.3347 129.339 12.3316C129.502 12.3326 129.66 12.2731 129.782 12.1645C129.904 12.056 129.982 11.9061 130 11.7437V3.07209C129.982 2.90971 129.904 2.75979 129.782 2.65124C129.66 2.54269 129.502 2.48318 129.339 2.48419Z"
                    fill="white"
                />
                <path
                    d="M38.8017 23.8692C38.0056 24.5534 36.9851 24.9197 35.9356 24.8981C35.2837 24.9807 34.6217 24.9236 33.9935 24.7306C33.3654 24.5375 32.7855 24.213 32.2925 23.7785C31.7995 23.344 31.4046 22.8096 31.1341 22.2107C30.8637 21.6118 30.7238 20.9622 30.7238 20.3051C30.7238 19.6479 30.8637 18.9983 31.1341 18.3994C31.4046 17.8005 31.7995 17.2661 32.2925 16.8316C32.7855 16.3972 33.3654 16.0726 33.9935 15.8796C34.6217 15.6865 35.2837 15.6294 35.9356 15.7121C37.001 15.7279 38.0325 16.0889 38.8752 16.7409C39.0139 16.8312 39.1814 16.8662 39.3447 16.839C39.508 16.8118 39.6551 16.7243 39.757 16.5939C39.8277 16.5448 39.885 16.4786 39.9235 16.4016C39.962 16.3246 39.9806 16.2391 39.9775 16.153C39.925 15.9592 39.8238 15.7822 39.6835 15.6386C38.5975 14.8369 37.2855 14.3996 35.9356 14.3893C35.1034 14.2964 34.2609 14.3801 33.4633 14.635C32.6656 14.89 31.9307 15.3103 31.3065 15.8687C30.6824 16.427 30.1831 17.1107 29.8413 17.8752C29.4994 18.6397 29.3228 19.4677 29.3228 20.3051C29.3228 21.1425 29.4994 21.9705 29.8413 22.7349C30.1831 23.4994 30.6824 24.1832 31.3065 24.7415C31.9307 25.2998 32.6656 25.7202 33.4633 25.9751C34.2609 26.23 35.1034 26.3138 35.9356 26.2209C37.2872 26.2209 38.6023 25.7825 39.6835 24.9716C39.8238 24.828 39.925 24.6509 39.9775 24.4571C39.9806 24.3711 39.962 24.2856 39.9235 24.2086C39.885 24.1315 39.8277 24.0654 39.757 24.0162C39.7154 23.9308 39.6537 23.8567 39.5773 23.8003C39.5008 23.744 39.4118 23.707 39.3178 23.6925C39.2239 23.6781 39.1279 23.6866 39.038 23.7174C38.9481 23.7482 38.867 23.8003 38.8017 23.8692Z"
                    fill="white"
                />
                <path
                    d="M50.7066 25.3389L46.8118 14.9771C46.7684 14.8537 46.6894 14.7459 46.5847 14.6674C46.4801 14.5889 46.3545 14.5433 46.2239 14.5361C46.083 14.5385 45.9457 14.5813 45.8284 14.6595C45.7112 14.7376 45.6189 14.8479 45.5625 14.9771L41.6676 25.3389C41.5942 25.4858 41.6676 25.6328 41.7411 25.8533C41.8 25.9838 41.9046 26.0883 42.0351 26.1472H42.329C42.4625 26.1515 42.5935 26.1101 42.7004 26.03C42.8072 25.9498 42.8836 25.8357 42.9169 25.7063L43.8723 23.3547H48.5755L49.4574 25.7798C49.5203 25.904 49.6142 26.0099 49.7301 26.0872C49.846 26.1644 49.9799 26.2104 50.1187 26.2207H50.3392C50.4302 26.1997 50.5148 26.1572 50.5861 26.097C50.6574 26.0367 50.7133 25.9603 50.7492 25.8741C50.7852 25.7879 50.8 25.6944 50.7926 25.6013C50.7852 25.5083 50.7557 25.4183 50.7066 25.3389ZM48.0611 22.0319H44.3867L46.2239 17.1082L48.0611 22.0319Z"
                    fill="white"
                />
                <path
                    d="M59.3782 20.4888C58.4481 19.9581 57.4256 19.6089 56.3652 19.46C55.5604 19.3414 54.7865 19.0668 54.087 18.6516C53.8922 18.5393 53.7292 18.3791 53.6134 18.1862C53.4977 17.9933 53.4331 17.7741 53.4257 17.5493C53.4243 17.2842 53.4911 17.0233 53.6198 16.7916C53.7486 16.56 53.9348 16.3653 54.1605 16.2265C54.7975 15.8248 55.5389 15.6203 56.2917 15.6386C57.336 15.7102 58.3346 16.0943 59.1577 16.7409C59.2964 16.8312 59.464 16.8662 59.6272 16.839C59.7905 16.8118 59.9376 16.7243 60.0396 16.5939C60.1071 16.4435 60.122 16.2748 60.082 16.1148C60.0421 15.9549 59.9494 15.813 59.8191 15.7121C58.8175 14.9009 57.5797 14.4367 56.2917 14.3893C55.277 14.3658 54.2786 14.6474 53.4257 15.1977C53.0512 15.4677 52.7437 15.8202 52.5271 16.228C52.3105 16.6357 52.1905 17.0878 52.1764 17.5493C52.1821 17.9823 52.3002 18.4064 52.5192 18.7801C52.7382 19.1537 53.0506 19.4639 53.4257 19.6804C54.2536 20.2028 55.1784 20.5527 56.1447 20.7093C57.0254 20.7986 57.8771 21.0742 58.6433 21.5176C58.8781 21.6528 59.0751 21.8447 59.2164 22.0758C59.3576 22.307 59.4385 22.5699 59.4517 22.8404C59.4293 23.1372 59.3379 23.4246 59.1848 23.6797C59.0317 23.9349 58.8211 24.1508 58.5698 24.3102C57.8851 24.7346 57.0972 24.9634 56.2917 24.9716C55.0119 24.9273 53.7888 24.4329 52.8378 23.5753C52.7076 23.4765 52.5456 23.4293 52.3827 23.4428C52.2199 23.4564 52.0679 23.5298 51.9559 23.6488C51.8976 23.709 51.8529 23.781 51.8247 23.8599C51.7966 23.9388 51.7856 24.0229 51.7926 24.1064C51.7995 24.1899 51.8242 24.271 51.8651 24.3441C51.9059 24.4173 51.962 24.4809 52.0294 24.5306C53.2099 25.5733 54.7174 26.1711 56.2917 26.2209C57.3753 26.2536 58.4379 25.9167 59.3047 25.2655C59.7193 25.0231 60.0643 24.6778 60.3065 24.263C60.5487 23.8483 60.6798 23.3781 60.6872 22.8979C60.6946 22.4176 60.578 21.9436 60.3487 21.5216C60.1194 21.0996 59.7851 20.7439 59.3782 20.4888Z"
                    fill="white"
                />
                <path
                    d="M63.7874 14.5361C63.612 14.5361 63.4438 14.6058 63.3197 14.7298C63.1957 14.8539 63.126 15.0221 63.126 15.1975V25.5593C63.126 25.7347 63.1957 25.903 63.3197 26.027C63.4438 26.151 63.612 26.2207 63.7874 26.2207C63.9629 26.2207 64.1311 26.151 64.2551 26.027C64.3792 25.903 64.4489 25.7347 64.4489 25.5593V15.1975C64.4489 15.0221 64.3792 14.8539 64.2551 14.7298C64.1311 14.6058 63.9629 14.5361 63.7874 14.5361Z"
                    fill="white"
                />
                <path
                    d="M75.9862 14.6097C75.8108 14.6097 75.6426 14.6793 75.5185 14.8034C75.3945 14.9274 75.3248 15.0956 75.3248 15.271V23.5017L69.0784 14.8301C69.0273 14.7552 68.9614 14.6917 68.8847 14.6434C68.808 14.595 68.7222 14.563 68.6326 14.5492C68.543 14.5354 68.4516 14.5402 68.3639 14.5632C68.2763 14.5863 68.1943 14.6271 68.123 14.6831C68.0205 14.7554 67.9439 14.8586 67.9042 14.9776C67.8645 15.0966 67.864 15.2252 67.9026 15.3445V25.5594C67.9026 25.6462 67.9197 25.7322 67.9529 25.8125C67.9862 25.8927 68.0349 25.9656 68.0963 26.027C68.1577 26.0885 68.2306 26.1372 68.3109 26.1704C68.3911 26.2037 68.4771 26.2208 68.564 26.2208C68.6508 26.2208 68.7368 26.2037 68.8171 26.1704C68.8973 26.1372 68.9702 26.0885 69.0316 26.027C69.0931 25.9656 69.1418 25.8927 69.175 25.8125C69.2082 25.7322 69.2254 25.6462 69.2254 25.5594V17.3287L75.3983 25.8533C75.403 25.91 75.4293 25.9626 75.4718 26.0003C75.5362 26.0724 75.6157 26.1295 75.7045 26.1676C75.7934 26.2057 75.8896 26.2239 75.9862 26.2208C76.1616 26.2208 76.3299 26.1511 76.4539 26.027C76.5779 25.903 76.6476 25.7348 76.6476 25.5594V15.271C76.6476 15.0956 76.5779 14.9274 76.4539 14.8034C76.3299 14.6793 76.1616 14.6097 75.9862 14.6097Z"
                    fill="white"
                />
                <path
                    d="M84.4377 14.3892C83.6849 14.4082 82.9432 14.5754 82.2551 14.8812C81.5669 15.187 80.9457 15.6255 80.427 16.1714C79.9084 16.7174 79.5024 17.3602 79.2322 18.0632C78.9621 18.7661 78.8331 19.5154 78.8527 20.2682C78.7619 21.0604 78.8397 21.8629 79.0808 22.623C79.3219 23.3831 79.721 24.0837 80.2518 24.6787C80.7826 25.2738 81.4332 25.75 82.1609 26.076C82.8886 26.402 83.6771 26.5706 84.4745 26.5706C85.2719 26.5706 86.0603 26.402 86.788 26.076C87.5158 25.75 88.1664 25.2738 88.6972 24.6787C89.228 24.0837 89.627 23.3831 89.8682 22.623C90.1093 21.8629 90.187 21.0604 90.0963 20.2682C90.1158 19.5093 89.9844 18.7541 89.7097 18.0464C89.435 17.3388 89.0224 16.6927 88.496 16.1458C87.9696 15.5988 87.3397 15.1619 86.6431 14.8604C85.9464 14.5588 85.1968 14.3986 84.4377 14.3892ZM84.4377 24.8979C83.2616 24.8407 82.1552 24.323 81.3577 23.4567C80.5601 22.5904 80.1354 21.445 80.1754 20.2682C80.1458 19.6867 80.2335 19.1051 80.4335 18.5583C80.6334 18.0114 80.9415 17.5104 81.3393 17.0852C81.7371 16.66 82.2164 16.3192 82.7488 16.0833C83.2811 15.8473 83.8555 15.7211 84.4377 15.7119C85.5558 15.8537 86.5839 16.3984 87.329 17.2439C88.0742 18.0895 88.4853 19.1779 88.4853 20.3049C88.4853 21.432 88.0742 22.5204 87.329 23.3659C86.5839 24.2115 85.5558 24.7562 84.4377 24.8979Z"
                    fill="white"
                />
                <path
                    d="M92.4475 24.0896C92.1675 24.089 91.8977 24.195 91.693 24.3861C91.4882 24.5771 91.3639 24.839 91.3452 25.1184C91.3452 25.4108 91.4614 25.6912 91.6681 25.8979C91.8748 26.1046 92.1552 26.2208 92.4475 26.2208C92.727 26.2021 92.9888 26.0778 93.1799 25.873C93.371 25.6683 93.477 25.3985 93.4764 25.1184C93.4764 24.8456 93.368 24.5839 93.1751 24.3909C92.9821 24.198 92.7204 24.0896 92.4475 24.0896Z"
                    fill="white"
                />
                <path
                    d="M103.692 23.8692C102.896 24.5534 101.875 24.9197 100.826 24.898C100.174 24.9807 99.5116 24.9236 98.8835 24.7305C98.2553 24.5375 97.6754 24.213 97.1824 23.7785C96.6894 23.344 96.2946 22.8096 96.0241 22.2107C95.7536 21.6118 95.6137 20.9622 95.6137 20.305C95.6137 19.6479 95.7536 18.9983 96.0241 18.3994C96.2946 17.8005 96.6894 17.2661 97.1824 16.8316C97.6754 16.3971 98.2553 16.0726 98.8835 15.8796C99.5116 15.6865 100.174 15.6294 100.826 15.712C101.891 15.7279 102.922 16.0889 103.765 16.7409C103.904 16.8312 104.071 16.8662 104.235 16.839C104.398 16.8117 104.545 16.7243 104.647 16.5939C104.794 16.5204 104.794 16.3 104.794 16.153C104.797 16.0563 104.779 15.9602 104.741 15.8713C104.703 15.7824 104.646 15.703 104.573 15.6386C103.487 14.8369 102.175 14.3996 100.826 14.3893C100.032 14.3697 99.2417 14.5091 98.5025 14.7995C97.7632 15.0898 97.0896 15.5252 96.5212 16.0799C95.9528 16.6346 95.5011 17.2974 95.1928 18.0294C94.8845 18.7613 94.7258 19.5476 94.7261 20.3418C94.7646 21.9272 95.4266 23.4335 96.5684 24.534C97.7102 25.6346 99.2398 26.2406 100.826 26.2208C102.175 26.2105 103.487 25.7732 104.573 24.9715C104.646 24.9071 104.703 24.8277 104.741 24.7388C104.779 24.6499 104.797 24.5538 104.794 24.4571C104.794 24.3101 104.794 24.0897 104.647 24.0162C104.596 23.9413 104.53 23.8778 104.453 23.8294C104.377 23.7811 104.291 23.7491 104.201 23.7353C104.112 23.7215 104.02 23.7263 103.932 23.7493C103.845 23.7724 103.763 23.8132 103.692 23.8692Z"
                    fill="white"
                />
                <path
                    d="M112.289 14.3892C111.536 14.4082 110.795 14.5754 110.107 14.8812C109.418 15.187 108.797 15.6255 108.279 16.1714C107.76 16.7174 107.354 17.3602 107.084 18.0632C106.814 18.7661 106.685 19.5154 106.704 20.2682C106.613 21.0604 106.691 21.8629 106.932 22.623C107.173 23.3831 107.573 24.0837 108.103 24.6787C108.634 25.2738 109.285 25.75 110.012 26.076C110.74 26.402 111.529 26.5706 112.326 26.5706C113.123 26.5706 113.912 26.402 114.64 26.076C115.367 25.75 116.018 25.2738 116.549 24.6787C117.08 24.0837 117.479 23.3831 117.72 22.623C117.961 21.8629 118.039 21.0604 117.948 20.2682C117.967 19.5093 117.836 18.7541 117.561 18.0464C117.287 17.3388 116.874 16.6927 116.348 16.1458C115.821 15.5988 115.191 15.1619 114.495 14.8604C113.798 14.5588 113.048 14.3986 112.289 14.3892ZM112.289 24.8979C111.113 24.8407 110.007 24.323 109.209 23.4567C108.412 22.5904 107.987 21.445 108.027 20.2682C108.007 19.6891 108.102 19.1118 108.306 18.5693C108.509 18.0268 108.818 17.5298 109.214 17.1066C109.61 16.6835 110.085 16.3425 110.613 16.1032C111.14 15.8639 111.71 15.7309 112.289 15.7119C113.407 15.8537 114.435 16.3984 115.181 17.2439C115.926 18.0895 116.337 19.1779 116.337 20.3049C116.337 21.432 115.926 22.5204 115.181 23.3659C114.435 24.2115 113.407 24.7562 112.289 24.8979Z"
                    fill="white"
                />
                <path
                    d="M129.853 14.9036L129.633 14.6832C129.494 14.5929 129.326 14.5579 129.163 14.5851C129 14.6123 128.853 14.6997 128.751 14.8301L125.003 20.0478L121.255 14.8301C121.211 14.7573 121.152 14.6949 121.082 14.6471C121.011 14.5994 120.932 14.5674 120.848 14.5534C120.764 14.5394 120.678 14.5438 120.596 14.5662C120.514 14.5886 120.438 14.6285 120.373 14.6832H120.226C120.079 14.7567 120.079 14.9036 120.079 15.1241V25.5594C120.079 25.7348 120.149 25.903 120.273 26.0271C120.397 26.1511 120.565 26.2208 120.74 26.2208C120.916 26.2208 121.084 26.1511 121.208 26.0271C121.332 25.903 121.402 25.7348 121.402 25.5594V17.2552L124.415 21.5176C124.566 21.6876 124.776 21.7929 125.003 21.8115C125.117 21.8115 125.229 21.7849 125.331 21.7339C125.434 21.6829 125.522 21.6088 125.591 21.5176L128.677 17.2552V25.5594C128.667 25.6425 128.674 25.727 128.699 25.807C128.724 25.887 128.765 25.9609 128.821 26.0235C128.877 26.0861 128.945 26.1361 129.022 26.1702C129.098 26.2042 129.181 26.2214 129.265 26.2208C129.352 26.2208 129.438 26.2037 129.518 26.1704C129.599 26.1372 129.671 26.0885 129.733 26.0271C129.794 25.9657 129.843 25.8927 129.876 25.8125C129.909 25.7323 129.927 25.6463 129.927 25.5594V15.2711C129.938 15.1441 129.913 15.0165 129.853 14.9036Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_2648_51239">
                    <rect width="130" height="27.1906" fill="white" transform="translate(0 0.5)" />
                </clipPath>
            </defs>
        </svg>
    );

    const logoMapper = new Map([
        ['fanduel', fanduel],
        ['mohegansun', mohegansun]
    ]);

    // For mohegan sun landscape we have a different logo
    if (process.env.APPLICATION.includes('mohegansun') && props.deviceType === 'landscape') {
        return mohegansun_full_logo;
    }

    for (const [brand, logo] of logoMapper.entries()) {
        if (process.env.APPLICATION.includes(brand)) {
            return logo;
        }
    }

    return null;
};

export default LogoIcon;
