import { useEffect } from 'react';
import './styles/default.scss'

const EmptyGame = () => {
    useEffect(() => {
        window.top.postMessage({ type: 'GC/SET_GAME_LOADED', payload: false }, '*')
    },[])

    return <div className="wrapper" >
        <button onClick={() => window.top.postMessage({ type: 'GC/SET_SPIN_IN_PROGRESS', payload: false }, '*')}> Spin FINISHED</button>
        <button onClick={() => window.top.postMessage({ type: 'GC/SET_SPIN_IN_PROGRESS', payload: true }, '*')}> Spin START</button>
    </div>
}

export default EmptyGame;
