import { WRAPPER_COOKIES } from '../constants';
import { PLATFORM } from '../constants/game-constants';
import { getAppPlatform } from '../helpers/app-platform';
import { logEvent as logEventAmplitude } from '@amplitude/analytics-browser';
import { isRunningInsideWrapper, sendGAEvent } from '../wrapper-bridge-mobile';
import Cookie from 'js-cookie';
import { amplitudeGtmMapper, AmplVal } from '../hooks/use-user-tracking/amplitude-gtm.mapper';

type GTMDefaultConfig = {
    gameId?: string;
    gameName?: string;
    gameProvider?: string;
};

export const getAndroidAppVersion = () =>
    navigator?.userAgent.toLowerCase().match(/version\/([0-9.]*)\sfanduelcasinoandroid/i)?.[1] ||
    navigator?.userAgent.toLowerCase().match(/version\/([0-9.]*)\sfanduelsportsbookandroid/i)?.[1] ||
    navigator?.userAgent.toLowerCase().match(/version\/([0-9.]*)\sstardustcasinoandroid/i)?.[1] ||
    undefined;

export const getAndroidDistribution = () => {
    const ua = navigator?.userAgent.toLowerCase();
    switch (true) {
        case ua.includes('selfdist'):
            return 'side-load';
        case ua.includes('playstore'):
            return 'play-store';
        default:
            return undefined;
    }
};

export const getDevice = () => {
    const platform = getAppPlatform();
    switch (platform) {
        case PLATFORM.Android:
            return 'Android';
        case PLATFORM.Ios:
            return 'iOS'
        case PLATFORM.Xsell:
            return 'x-sell';
        default:
            return 'desktop';
    }
};

export const launchedFrom = () => 'web_launcher';

export const gtmPushToDatalayer = (payload, defaultConfig: GTMDefaultConfig) => {
    const dataObject = { ...payload, defaultConfig };
    if (window['dataLayer']) {
        window['dataLayer'].push(dataObject);
        process.env.NODE_CONFIG_ENV === 'local-development' && console.log('GTM event: ', dataObject);
    }

    if (getAppPlatform() !== PLATFORM.Web) {
        executeLog(dataObject);
    }

    if (getAppPlatform() === PLATFORM.Xsell && isRunningInsideWrapper()) {
        delete dataObject['gtm.uniqueEventId'];
        const serializedDataObject = JSON.stringify(dataObject);
        sendGAEvent(serializedDataObject);
    }
};

const executeLog = dataObject => {
    if (!dataObject) {
        return;
    }
    if (window['FirebaseAnalyticsWebInterface']) {
        // Call Android interface
        delete dataObject['gtm.uniqueEventId'];
        const { event, ...params } = dataObject;
        window['FirebaseAnalyticsWebInterface'].logEvent(event, JSON.stringify(params));
    } else if (window['webkit'] && window['webkit'].messageHandlers && window['webkit'].messageHandlers.firebase) {
        // Call iOS interface
        const { event, ...params } = dataObject;
        const message = {
            command: 'logEvent',
            name: event,
            parameters: params
        };
        window['webkit'].messageHandlers.firebase.postMessage(message);
    } else {
        // No Android or iOS interface found
        console.log('No native APIs found.');
    }
};

const filterPayloadForXsell = (payload: Record<string, unknown>): Record<string, unknown> => {
    const gaEventCategory = 'gaEventCategory';
    const gaEventLabel = 'gaEventLabel';
    const gaEventAction = 'gaEventAction';
    const { [gaEventCategory]: eventCategory, [gaEventLabel]: eventLabel, [gaEventAction]: eventAction } = payload;

    if (eventCategory) {
        delete payload[gaEventCategory];
        payload.eventCategory = eventCategory;
    }

    if (eventLabel) {
        delete payload[gaEventLabel];
        payload.eventLabel = eventLabel;
    }

    if (eventAction) {
        delete payload[gaEventAction];
        payload.eventAction = eventAction;
    }

    return { ...payload };
};

const mapAndPushGTMEvent = (defaultConfig: GTMDefaultConfig, payload: Record<string, unknown>) => {
    const platform = getAppPlatform();
    if (platform === PLATFORM.Xsell) {
        const isQL = Cookie.get(WRAPPER_COOKIES.IS_QUICK_LAUNCH) !== 'false';

        gtmPushToDatalayer(
            { ...filterPayloadForXsell({ ...payload }), module: isQL ? 'ql' : 'xsell_lobby' },
            defaultConfig
        );
    }

    gtmPushToDatalayer({ ...payload, module: 'standalone_lobby' }, defaultConfig);
};



export const logTrackingEvent = (payload: AmplVal, pushGA = true) => {
    payload['Launched From'] = launchedFrom();
    const { Event, ...rest } = payload;

    logEventAmplitude(Event as string, rest);
    if (pushGA) {
        const converted = amplitudeGtmMapper(payload);

        const defaultConfig = {
            gameId: converted.gameId as string,
            gameName: converted.gameName as string,
            gameProvider: converted.gameProvider as string
        };

        delete converted.gameId;
        delete converted.gameName;
        delete converted.gameProvider;

        mapAndPushGTMEvent(defaultConfig, converted);
    }
};
