import jackpotFeedMock, { JackpotFeedResponse } from "./mock";

const getJackpotFeed = async (init = false): Promise<JackpotFeedResponse> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(jackpotFeedMock(init));
    }, 1000);
  });
};

const jackpotFeed = async (
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  method: (arg: JackpotFeedResponse) => void = () => {},
  persistValue = false,
  timeout = 10000,
) => {
  const interval = setInterval(async () => {
    method(await getJackpotFeed());
  }, timeout);

  if (!persistValue) {
    clearInterval(interval);
  }

  const result = await getJackpotFeed(true);
  method(result);

  return result;
};

export default jackpotFeed;
