import { PLATFORM } from '../../../common/constants/game-constants'

import GeoComplyWeb from './geo-comply-web'
import GeoComplyXsell from './geo-comply-xsell'

const geoComplyByPlatformMap = {
    [PLATFORM.Web]: GeoComplyWeb,
    [PLATFORM.Android]: GeoComplyWeb,
    [PLATFORM.Xsell]: GeoComplyXsell,
    [PLATFORM.Ios]: GeoComplyXsell,
}

/**
 * GeoComply factory by platform context
 *
 * @param {string} platform
 * @returns {function} current GeoComply component
 */
export default function geoComplyFactory(platform) {
    return geoComplyByPlatformMap[platform]
}
