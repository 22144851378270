import { datadogRum } from '@datadog/browser-rum';
import Cookie from 'js-cookie';

import { types } from '../../../types';
import config from '../../../../../config/config';
import axiosAWInstance from '../../../../common/axios/AWAxios';
import { AW_COOKIE } from '../../../../common/constants';
import connectionType from '../../../../common/helpers/connectionType';
import { ddRumAction } from '../../../../common/helpers/datadog-wrapper';
import { ddActionGetUserBalanceReceived } from '../../../../common/datadog/get-user/gu-balance-received';
import { DD_USE_CASES } from '../../../../common/datadog/enums';
import { logTrackingEvent } from '../../../../common/helpers/user-tracking.helpers';
import { gameIdSelector, gameNameSelector } from '../../../../selectors/gameContext';
import { getAmplitudeKey } from '../../../../common/hooks/use-user-tracking/types';
import { gameProviderNameSelector } from '../../../../selectors/game-window';

export const getBalanceDataWeb = initialFetch => async (dispatch, getState) => {
    if (initialFetch) {
        dispatch({ type: types.GET_USER_BALANCE_REQUEST });
    }
    const authToken = Cookie.get(AW_COOKIE.AUTH_TOKEN) || null;
    const gameName = gameNameSelector(getState());
    const gameProviderName = gameProviderNameSelector(getState());
    const gameId = gameIdSelector(getState());

    await axiosAWInstance
        .get(config?.accountAndWalletApi?.endpoints?.USER_BALANCE, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Basic ${config.clientID}`,
                'Cache-Control': 'no-store, no-cache',
                [AW_COOKIE.AUTH_TOKEN]: authToken
            }
        })
        .then(response => {
            if (response && response?.data?.wallet_balances) {
                const details = response?.data?.wallet_balances.reduce(
                    (accumulator, { account_type, balance }) => ({
                        ...accumulator,
                        [account_type]: balance
                    }),
                    {}
                );
                const playableBalance = details['CASINO_PLAYABLE'];
                const casinoDeposits = details['USER_SPORTSBOOK_DEPOSIT'];
                const casinoBonuses = details['USER_CASINO_BONUS'];
                const sharedWinnings = details['SHARED_WINNINGS'];

                dispatch({
                    type: types.GET_USER_BALANCE_RECEIVED,
                    payload: { playableBalance, casinoDeposits, casinoBonuses, sharedWinnings }
                });

                ddRumAction(ddActionGetUserBalanceReceived(DD_USE_CASES.GAME_LAUNCHER));
            }
        })
        .catch(error => {
            dispatch({
                type: types.GET_USER_BALANCE_FAILED,
                payload: { name: error.name }
            });

            datadogRum.addError(
                new Error(error),
                Object.assign(
                    {
                        type: '[GET_USER_BALANCE]: ERROR',
                        connectionType: connectionType()
                    },
                    error
                ),
                'custom'
            );

            logTrackingEvent({
                [getAmplitudeKey('GAME_ID')]: gameId,
                [getAmplitudeKey('GAME_NAME')]: gameName,
                [getAmplitudeKey('GAME_PROVIDER')]: gameProviderName,
                [getAmplitudeKey('EVENT')]: 'Game Launch Failed',
                [getAmplitudeKey('COMPONENT')]: 'a&w'
            });
        });
};

export default getBalanceDataWeb;
