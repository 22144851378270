import { createSelector } from 'reselect';

export const gameClientStatusSelector = (state: AppState) => state.gameClientStatus;

export const clientReadySelector = createSelector(gameClientStatusSelector, ctx => ctx?.clientReady);

export type AppState = {
    gameClientStatus: {
        clientReady: boolean;
    };
};
