import { PLATFORM } from '../../constants/game-constants'

import useGeocomplyFlowWeb from './use-geocomply-flow-web'
import useGeocomplyFlowAndroid from './use-geocomply-flow-android'
import { isWrapperRunningNewGeocomply } from '../../wrapper-bridge-mobile'

const geocomplyFlowPlatformMap = {
    [PLATFORM.Web]: useGeocomplyFlowWeb,
    [PLATFORM.Android]: isWrapperRunningNewGeocomply() ? useGeocomplyFlowWeb : useGeocomplyFlowAndroid,
    [PLATFORM.Xsell]: useGeocomplyFlowAndroid,
    [PLATFORM.Ios]: useGeocomplyFlowAndroid
}

/**
 * useGeocomplyFlow factory by platform context
 *
 * @param {string} platform
 * @returns {function} current useGeocomplyFlow hook
 */
export default function useGeocomplyFlowFactory(platform) {
    return geocomplyFlowPlatformMap[platform]
}
