import { PLATFORM } from '../../constants/game-constants'

import useGeolocationVerifyAndroid from './use-geolocation-verify-android'
import useGeolocationVerifyXsell from './use-geolocation-verify-xsell'
import useGeolocationVerifyIos from './use-geolocation-verify-ios'
import VoidService from '../../helpers/void-service'

export const geolocationVerifyPlatformMap = {
    [PLATFORM.Web]: VoidService,
    [PLATFORM.Android]: useGeolocationVerifyAndroid,
    [PLATFORM.Xsell]: useGeolocationVerifyXsell,
    [PLATFORM.Ios]: useGeolocationVerifyIos
}

/**
 * useGeolocationVerify factory by platform context
 *
 * @param {string} platform
 * @returns {function} current useGeolocationVerify hook
 */
export default function useGeolocationVerifyFactory(platform) {
    return geolocationVerifyPlatformMap[platform]
}
