import { useEffect, useMemo } from 'preact/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerFeatures } from '../../redux/actions/customer-data';
import { ddActionUserEligibleShowGameWindow } from '../datadog/common/user-eligible-show-game-window';
import { DD_USE_CASES } from '../datadog/enums';
import { ddRumAction } from './datadog-wrapper';

const useCheckEligibilityForGameWindow = () => {
    const dispatch = useDispatch();
    const customerFeatures = useSelector(state => state?.customerData?.features);

    useEffect(() => {
        dispatch(getCustomerFeatures());
    }, [dispatch]);

    return useMemo(() => {
        const isArray = customerFeatures && Array.isArray(customerFeatures);
        const includesGameWindow = isArray && customerFeatures.includes('game-window');
        if (includesGameWindow) {
            ddRumAction(ddActionUserEligibleShowGameWindow(DD_USE_CASES.GAME_LAUNCHER));
            return true;
        }
        return false;
    }, [customerFeatures]);
};

export default useCheckEligibilityForGameWindow;
