import { useCallback, useEffect, useMemo } from 'preact/hooks';
import { GAME_CLIENT_MESSAGES } from '../types';
import { useGameClientHandlers, ProviderErrorPayload } from './useGameClientHandlers';

export const useGameClientListener = () => {
    const {
        handleGameClientHandshake,
        handleGameLoadStarted,
        handleGameLoaded,
        handleLoadProgress,
        handleAudioEnabled,
        handleResetGame,
        handleSpinInProgress,
        handleProviderError
    } = useGameClientHandlers();

    const eventHandlerMapper: EventHandlerMap = useMemo(
        () => ({
            [GAME_CLIENT_MESSAGES.GAME_CLIENT_HANDSHAKE]: handleGameClientHandshake,
            [GAME_CLIENT_MESSAGES.GAME_LOADED]: handleGameLoaded,
            [GAME_CLIENT_MESSAGES.GAME_LOAD_STARTED]: handleGameLoadStarted,
            [GAME_CLIENT_MESSAGES.SPIN_IN_PROGRESS]: handleSpinInProgress,
            [GAME_CLIENT_MESSAGES.AUDIO_ENABLED]: handleAudioEnabled,
            [GAME_CLIENT_MESSAGES.PROVIDER_ERROR]: handleProviderError,
            [GAME_CLIENT_MESSAGES.GAME_LOAD_PROGRESS]: handleLoadProgress,
            [GAME_CLIENT_MESSAGES.GAME_RESET]: handleResetGame
        }),
        [
            handleGameClientHandshake,
            handleAudioEnabled,
            handleGameLoadStarted,
            handleGameLoaded,
            handleLoadProgress,
            handleProviderError,
            handleSpinInProgress,
            handleResetGame
        ]
    );

    const eventHandler = useCallback(
        (event: GameClientEvent) => {
            if (event?.data?.type) {
                eventHandlerMapper[event.data.type]?.(event.data.payload);
            }
        },
        [eventHandlerMapper]
    );

    useEffect(() => {
        window.addEventListener('message', eventHandler);

        return () => window.removeEventListener('message', eventHandler);
    }, [eventHandler]);
};

type EventHandlerMap = {
    [key: string]: (event: GameClientEventPayload) => void;
};

type GameClientEventPayload = boolean | number | ProviderErrorPayload;

type GameClientEvent = {
    data: {
        type: string;
        payload: GameClientEventPayload;
    };
};
