import { useDispatch } from 'react-redux';
import { types } from '../../redux/types';

const fetchSWJData = async () => {
    const response = await fetch("http://localhost:3333/minorJackpot", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            userId: "49131",
            amount: 213
        })
    });
    const json = await response.json();
    return JSON.parse(json?.response?.data?.addMessage?.payload || '{}')?.jackpotTierId;
}

export const SiteWideJackpotWinJackpotMock = () => {
    const dispatch = useDispatch();

    const handleFetchMockData = async () => {
        const jackpotId = await fetchSWJData();
        dispatch({
            type: types.SET_SWJ_JACKPOT_DATA,
            payload: {
                jackpotId
            }
        });
    }

    return (
        <div>
            <h3>SiteWideJackpot Debug</h3>
            <button className="button-trigger-jackpot-win" onClick={handleFetchMockData}>WIN JACKPOT</button>
            <hr />
        </div>
    );
}

export const SiteWideJackpotWinJackpot = ({setShowJackpotWinScreen, jackpotType, setJackpotType}) => {

    const handleWinJackpot = () => {
        setShowJackpotWinScreen(true);
        setJackpotType(jackpotType)
    }

    return (
        <div>
            <h3>SiteWideJackpot Debug</h3>
            <button className="button-trigger-jackpot-win" onClick={handleWinJackpot}>{`WIN ${jackpotType.toUpperCase()}`}</button>
            <hr />
        </div>
    );
}
