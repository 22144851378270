import { types } from '../../types';
import { getBalanceData } from '../accountAndWallet/get-user-balance';

export const openMenu = () => dispatch => {
    const fetchBalance = getBalanceData('Web');

    dispatch(fetchBalance());
    dispatch({
        type: types.OPEN_MENU
    });
};

export const closeMenu = () => dispatch => {
    dispatch({
        type: types.CLOSE_MENU
    });
};
