import { PLATFORM } from '../../../../common/constants/game-constants'

import fetchLocationAndroid from './fetch-location-android'
import VoidService from '../../../../common/helpers/void-service'

const fetchLocationByPlatformMap = {
    [PLATFORM.Web]: VoidService,
    [PLATFORM.Android]: fetchLocationAndroid,
    [PLATFORM.Xsell]: VoidService,
    [PLATFORM.Ios]: VoidService
}
/**
 * fetch location factory by platform context
 *
 * @param {string} platform
 * @returns {function(...[*]): function(*): Promise<void>} current fetch location action creator
 */
export default function fetchLocationFactory(platform) {
    return function fetchLocation(...options) {
        return fetchLocationByPlatformMap[platform](...options)
    }
}
